import { useContext, useEffect } from "react";

import {
  AmplitudeFlagKey,
  Nullable,
  Undefinable,
  getOrganizationClosingDate,
  convertTimestampToDateNumber,
  PaymentMethod,
} from "@simplyk/common";
import { useRouter } from "next/router";

import { AmplitudeContext } from "../components/PaymentProcessor/type";
import { FrontendFormContext } from "../contexts/FrontendFormContext";
import { useLocaleContext } from "../contexts/LocaleContext";
import { FormType, OrganizationObject } from "../gql/gql-types";
import { appendUserProperty } from "../helpers/amplitude";
import { isDonationForm, isTicketing } from "../helpers/form";

import { useAmplitudeFeatureFlag } from "./amplitude/useAmplitudeFeatureFlag";
import { useMediaQuery } from "./useMediaQuery";
import { useScreenSize } from "./useScreenSize";

import { AmplitudeEvents, FormVisitParams } from "@/constants/amplitude";
import { useLogVisitPageAmplitudeEvent } from "@/hooks/amplitude/useLogVisitPageAmplitudeEvent";
import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

interface DonationFormAmplitudeContextProps {
  formData: DonationFormOutput | TicketingOutput;
  formAmplitudeContext: AmplitudeContext;
}

export const useLogFormAmplitude = ({ formData, formAmplitudeContext }: DonationFormAmplitudeContextProps) => {
  const router = useRouter();
  const { locale, isoLocale } = useLocaleContext();
  const { displayExpressCheckout } = useContext(FrontendFormContext);

  const { isActive: isCashappEnabled } = useAmplitudeFeatureFlag({
    flagKey: AmplitudeFlagKey.IsCashappEnabled,
    userProperties: { organizationId: formData.organization?.id || "", formId: formData.id },
    organizationId: formData.organization?.id,
  });

  const params: FormVisitParams = {
    count_custom_questions: formData.questions?.length || 0,
    display_address_question:
      ((isDonationForm(formData) || isTicketing(formData)) && formData.displayAddressQuestion) || null,
    encourage_monthly_donations: isDonationForm(formData) && formData.encourageMonthlyDonations,
    form_id: formData.id,
    form_type: isDonationForm(formData) ? formData.category : (formData.formCategory ?? FormType.Ticketing),
    has_a_linked_ticketing: isDonationForm(formData) && Boolean(formData.redirectUrl),
    has_additional_donation: isTicketing(formData) && formData.extraDonation,
    has_banner: Boolean(formData.bannerUrl),
    has_banner_video: Boolean(formData.bannerVideoUrl),
    has_check_option: (isDonationForm(formData) || isTicketing(formData)) && Boolean(formData.allowCheque),
    has_in_honour_option: isDonationForm(formData) && Boolean(formData.allowInHonour),
    has_logo: Boolean(formData.logoUrl),
    has_no_suggested_amount: formAmplitudeContext.hasNoSuggestedAmount || null,
    has_receipt: isDonationForm(formData) && formData.hasReceipt,
    has_receipt_message: formAmplitudeContext.hasReceiptMessage || null,
    has_target: isDonationForm(formData)
      ? Boolean(formData.target)
      : isTicketing(formData)
        ? Boolean(formData.thermometerTarget)
        : false,
    is_embed: router.query.embed === "true",
    is_primary_team: isDonationForm(formData) && formData.isPrimaryTeam,
    is_primary_campaign: isDonationForm(formData) && formData.isPrimaryCampaign,
    iso_locale: isoLocale,
    locale,
    organization_country: formData.organization?.country || null,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    AB_test_cashapp: isCashappEnabled,
    fast_checkout_available: displayExpressCheckout,
  };

  useEffect(() => {
    if (formData.organization) {
      const closingDate = getOrganizationClosingDate(formData.organization as OrganizationObject);
      if (closingDate) {
        const formattedClosingDate = convertTimestampToDateNumber(closingDate);
        appendUserProperty("donor_organization_closing_date", formattedClosingDate);
      }
    }
  }, [formData.organization]);

  useLogVisitPageAmplitudeEvent({
    event: AmplitudeEvents.DonorFormViewed,
    payload: {
      isForm: true,
      path: router.pathname,
      ...router.query,
      ...formAmplitudeContext,
      ...params,
    },
  });
};

export const useFormAmplitudeContext = ({
  formData,
  field,
  isEmbed,
  organization,
  selectedPaymentMethod,
}: {
  formData: DonationFormOutput | TicketingOutput;
  field?: { donationFormAmounts?: { id: string }[] | null };
  isEmbed: boolean;
  organization: Nullable<OrganizationObject>;
  selectedPaymentMethod: Undefinable<PaymentMethod>;
}) => {
  const { isSmallScreen } = useMediaQuery();
  const { screenSize } = useScreenSize();

  const formAmplitudeContext = {
    paymentMethod: selectedPaymentMethod,
    isMobile: isSmallScreen,
    isEmbed,
    screenSize,
    hasReceiptMessage: isDonationForm(formData) && formData.hasReceipt && Boolean(organization?.canGenerateReceipt),
    hasNoSuggestedAmount: field ? !field?.donationFormAmounts || field?.donationFormAmounts.length === 0 : undefined,
  };

  return { formAmplitudeContext };
};
