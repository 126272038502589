import { getDatePlusNDays } from "./dates";
export const isAchPadAllowed = ({ currentUtc = new Date(), startUtc, endUtc, }) => {
    const hasMinimumDaysBeforeEvent = (days) => {
        const minDateRequired = getDatePlusNDays(currentUtc, days);
        return endUtc ? new Date(endUtc) >= minDateRequired : startUtc ? new Date(startUtc) >= minDateRequired : true;
    };
    return {
        isPadAllowed: hasMinimumDaysBeforeEvent(10),
        isAchAllowed: hasMinimumDaysBeforeEvent(10),
    };
};
