import { useContext, useEffect, useRef } from "react";

import { NotificationBannerHeightContext } from "./NotificationBannerHeightContext";

/**
 * Hook to keep the `bannerHeight` state updated with the real height of the banner,
 * allowing other components to adjust their layout accordingly.
 */
export const useSyncNotificationBannerHeight = () => {
  const { setBannerHeight } = useContext(NotificationBannerHeightContext);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const handleResize = () => {
      if (ref.current) {
        setBannerHeight(ref.current.offsetHeight);
      }
    };

    // Initial height measurement
    handleResize();

    // Use ResizeObserver to detect changes in the component's height
    const observer = new ResizeObserver(() => {
      handleResize();
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [setBannerHeight]);

  return ref;
};
