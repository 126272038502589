import { AvailablePaymentCurrency, IsoLocale, formatCurrencyAmount, getDollarValue } from "@simplyk/common";

import { DiscountObject, DiscountType } from "../gql/gql-types";

export const applyDiscountOnOrderValue = (validDiscount: DiscountObject | null, ticketsPrice: number) => {
  return Math.max(ticketsPrice - getDiscountAmount(validDiscount, ticketsPrice), 0);
};

export const getDiscountAmount = (validDiscount: DiscountObject | null, ticketsPrice: number) => {
  if (!validDiscount) {
    return 0;
  }
  if (validDiscount.type === DiscountType.Dollars) {
    return validDiscount.amount;
  }
  return Math.round((ticketsPrice * validDiscount.amount) / 100);
};

export const getDiscountReduction = (
  validDiscount: DiscountObject,
  isoLocale: IsoLocale,
  currency: AvailablePaymentCurrency
) => {
  if (!validDiscount) {
    return "";
  }
  if (validDiscount.type === DiscountType.Dollars) {
    return formatCurrencyAmount(getDollarValue(-validDiscount.amount), isoLocale, currency);
  }
  return `-${validDiscount.amount}%`;
};
