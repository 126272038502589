import countryRegionData from "country-region-data";
import { CountryCode, CountryNames, OrganizationCountry } from "../types/countryRegion";
export const getOrganizationCountryFromCountryRegionData = (value) => {
    if (value === CountryNames.UnitedStates) {
        return OrganizationCountry.UnitedStates;
    }
    if (value === CountryNames.Canada) {
        return OrganizationCountry.Canada;
    }
    if (value === CountryNames.GreatBritain) {
        return OrganizationCountry.GreatBritain;
    }
    return OrganizationCountry.Canada;
};
export const getCountryRegionDataFromOrganizationCountry = (value) => {
    if (value === OrganizationCountry.UnitedStates) {
        return CountryNames.UnitedStates;
    }
    if (value === OrganizationCountry.Canada) {
        return CountryNames.Canada;
    }
    return CountryNames.Canada;
};
export const getCountryNamesFromString = (value) => {
    if (value === CountryNames.UnitedStates || value === OrganizationCountry.UnitedStates) {
        return CountryNames.UnitedStates;
    }
    if (value === CountryNames.Canada || value === OrganizationCountry.Canada) {
        return CountryNames.Canada;
    }
    return CountryNames.Canada;
};
export const getCountry = (countryName) => {
    return countryRegionData.find((elem) => {
        return elem.countryName === countryName;
    });
};
export const getRegion = (countryName, regionName) => {
    const country = getCountry(countryName);
    return country?.regions.find((region) => region.name === regionName);
};
export const getCountryShortCodeFromOrganizationCountry = (organizationCountry) => {
    const country = getCountry(getCountryRegionDataFromOrganizationCountry(organizationCountry));
    return country?.countryShortCode;
};
export const getCountryShortCodeFromCountryName = (countryName) => {
    const country = getCountry(countryName);
    return country?.countryShortCode;
};
export const getRegionShortCodeFromCountryAndRegionName = (countryName, regionName) => {
    const region = getRegion(countryName, regionName);
    return region?.shortCode;
};
export const getRegionFromCountryAndRegionShortCode = (organizationCountry, shortCode) => {
    const country = getCountry(getCountryRegionDataFromOrganizationCountry(organizationCountry));
    return country?.regions.find((region) => region.shortCode === shortCode)?.name;
};
export const formatCanadianRegion = (region) => {
    return region === "Québec" ? "Quebec" : region;
};
export const formatWithAccentCanadianRegion = (region) => {
    return region?.toLowerCase() === "quebec" ? "Québec" : region;
};
export const convertCountryToCountryCode = (country) => {
    if (country === CountryNames.UnitedStates ||
        country === OrganizationCountry.UnitedStates ||
        country === CountryNames.Canada ||
        country === OrganizationCountry.Canada) {
        return CountryCode[getCountry(getCountryNamesFromString(country))?.countryShortCode];
    }
    return CountryCode[getCountry(country)?.countryShortCode];
};
export const convertCountryCodeToCountry = (countryCode) => {
    const foundCountryRegion = countryRegionData.find((countryRegionItem) => countryRegionItem.countryShortCode === countryCode);
    return foundCountryRegion?.countryName;
};
