import { useMediaQuery } from "../hooks/useMediaQuery";

export enum ScreenSize {
  IsXsDown = "isXsDown",
  IsSmDown = "isSmDown",
  IsMdDown = "isMdDown",
  IsLgDown = "isLgDown",
  IsXlDown = "isXlDown",
  IsXlUp = "isXlUp",
}

export const useScreenSize = () => {
  const isXsDown = useMediaQuery((theme) => theme.breakpoints.down("sm")).value;
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("md")).value;
  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down("lg")).value;
  const isLgDown = useMediaQuery((theme) => theme.breakpoints.down("xl")).value;
  const isXlDown = useMediaQuery((theme) => theme.breakpoints.down("xl")).value;

  return {
    screenSize: getScreenSize(isXsDown, isSmDown, isMdDown, isLgDown, isXlDown),
  };
};

const getScreenSize = (
  isXsDown: boolean,
  isSmDown: boolean,
  isMdDown: boolean,
  isLgDown: boolean,
  isXlDown: boolean
) => {
  if (isXsDown) {
    return ScreenSize.IsXsDown;
  }
  if (isSmDown) {
    return ScreenSize.IsSmDown;
  }
  if (isMdDown) {
    return ScreenSize.IsMdDown;
  }
  if (isLgDown) {
    return ScreenSize.IsLgDown;
  }
  if (isXlDown) {
    return ScreenSize.IsXlDown;
  }

  return ScreenSize.IsXlUp;
};
