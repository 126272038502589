import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";

import { SelectChangeEvent } from "@mui/material/Select";
import {
  AvailablePaymentCurrency,
  DonationFormCategory,
  TicketingFormCategory,
  formatCurrencyAmount,
  getDollarValue,
  getFeesFromCentValue,
  getCentsValue,
  Undefinable,
  OrganizationCountry,
  PaymentMethod,
} from "@simplyk/common";

import { useLocaleContext } from "../../../contexts/LocaleContext";
import { trpc } from "../../../helpers/trpc";

import { AmplitudeEvents } from "@/constants/amplitude";
import { NumberConstant } from "@/constants/number";
import { useAmplitude } from "@/hooks/amplitude/useAmplitude";
import { FeatureFlagValue, Maybe, TipSuggestionObject } from "@/src/gql/gql-types";
import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

const defaultTipSuggestions: [TipSuggestionObject, TipSuggestionObject, TipSuggestionObject] = [
  {
    percentage: 5,
    amount: 0,
  },
  {
    percentage: 10,
    amount: 0,
  },
  {
    percentage: 15,
    amount: 0,
  },
];

export type SelectedTip = Partial<Pick<TipSuggestionObject, "percentage" | "amount">> & {
  isFree?: boolean;
};

export const useComputeTip = ({
  displayedFormAmount,
  currency,
  organizationCountry,
  paymentMethod,
  isAuction,
  category,
  formData,
}: {
  displayedFormAmount: number;
  currency: AvailablePaymentCurrency;
  organizationCountry: Undefinable<OrganizationCountry>;
  paymentMethod: Undefinable<PaymentMethod>;
  isAuction: boolean;
  category: DonationFormCategory | Maybe<TicketingFormCategory> | undefined;
  formData: TicketingOutput | DonationFormOutput;
}) => {
  const { logAmplitudeEvent } = useAmplitude();
  const [selectedTip, setSelectedTip] = useState<SelectedTip>({
    amount: 0,
    percentage: 0,
    isFree: false,
  });

  const [suggestedTips, setSuggestedTips] =
    useState<[TipSuggestionObject, TipSuggestionObject, TipSuggestionObject]>(defaultTipSuggestions);
  const [isSuggestedTipsAmount, setIsSuggestedTipsAmount] = useState(false);
  const [tipExperimentValue, setTipExperimentValue] = useState<FeatureFlagValue | null>(null);
  const [freeTips, setFreeTips] = useState<number | "">("");
  const { isoLocale } = useLocaleContext();

  const { refetch: refetchTipsSuggestion } = trpc.form_getFrontendTipSuggestions.useQuery(
    {
      amountInCents: displayedFormAmount,
      organizationCountry: organizationCountry as OrganizationCountry,
    },
    {
      enabled: !(!displayedFormAmount || displayedFormAmount >= NumberConstant.GRAPHQL_MAX_INT || !organizationCountry),
    }
  );

  // When amount is changed => Refresh suggested tip
  useEffect(() => {
    let isCancelled = false;

    if (displayedFormAmount === 0 || paymentMethod === PaymentMethod.Cheque) {
      setSuggestedTips(defaultTipSuggestions);
      setSelectedTip(defaultTipSuggestions[0]);
      setIsSuggestedTipsAmount(false);
      setTipExperimentValue(null);
      return;
    }

    if (!displayedFormAmount || displayedFormAmount >= NumberConstant.GRAPHQL_MAX_INT) {
      return;
    }

    const getTipSuggestionObjectAndResetTipInput = async () => {
      const { data } = await refetchTipsSuggestion();

      if (!data) {
        return;
      }

      const tipSuggestions = data?.items as [TipSuggestionObject, TipSuggestionObject, TipSuggestionObject] | undefined;

      if (tipSuggestions && !isCancelled) {
        setSuggestedTips(tipSuggestions);
        setSelectedTip(tipSuggestions[0]);
        setIsSuggestedTipsAmount(!isAuction && Boolean(data?.isSuggestedTipsAmount));
        setTipExperimentValue(data?.featureFlagValue || null);
      }
    };

    getTipSuggestionObjectAndResetTipInput();

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedFormAmount]);

  const tip = useMemo(() => {
    return paymentMethod === PaymentMethod.Cheque
      ? 0
      : selectedTip.isFree
        ? isNaN(freeTips as number)
          ? 0
          : getCentsValue(freeTips as number)
        : Math.round(selectedTip.amount as number);
  }, [freeTips, paymentMethod, selectedTip]);

  const fees = useMemo(() => getDollarValue(getFeesFromCentValue(displayedFormAmount)), [displayedFormAmount]);

  const formattedFees = useMemo(() => formatCurrencyAmount(fees, isoLocale, currency), [fees, isoLocale, currency]);

  const handleChangeTips = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const selectedTip = suggestedTips.find((tip) => tip.percentage === event.target.value);

      if (!selectedTip || event.target.value === "free") {
        setSelectedTip({ isFree: true });
        return;
      }
      setSelectedTip(selectedTip as TipSuggestionObject);
    },
    [suggestedTips]
  );

  const getTipChoice = useCallback(
    (value: string) => {
      if (value === "free") {
        return "custom";
      }
      return suggestedTips.findIndex((suggestedTip) => suggestedTip.percentage === Number(value)) + 1;
    },
    [suggestedTips]
  );

  const handleChangeFreeTips = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const freeTip = event.target.value === "" || event.target.value === null ? "" : Number(event.target.value);
    setFreeTips(freeTip);
  }, []);

  const logAmplitudeTipsChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      logAmplitudeEvent(AmplitudeEvents.DonorFormTipChanged, {
        amount: getDollarValue(displayedFormAmount),
        form_id: formData.id,
        form_type: category,
        organization_country: organizationCountry,
        tip_choice: "custom",
        tip_amount: Number(event.target.value),
      });
    },
    [category, displayedFormAmount, formData.id, logAmplitudeEvent, organizationCountry]
  );

  const resetTipToCoverBankCharge = useCallback(() => {
    setFreeTips(getDollarValue(Math.round(getFeesFromCentValue(displayedFormAmount))));
  }, [displayedFormAmount]);

  const updateFreeTipAmount = useCallback((amount: number) => {
    setFreeTips(amount);
  }, []);

  return {
    tip,
    suggestedTips,
    selectedTip,
    isSuggestedTipsAmount,
    handleChangeTips,
    handleChangeFreeTips,
    resetTipToCoverBankCharge,
    freeTips,
    updateFreeTipAmount,
    fees,
    formattedFees,
    tipExperimentValue,
    getTipChoice,
    logAmplitudeTipsChanged,
    displayedFormAmount,
  };
};
