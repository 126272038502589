import { forwardRef } from "react";

import classNames from "classnames";

import { useCurrentInputVibe } from "../../../hooks/useCurrentInputVibe";
import { OptionLabel } from "../OptionLabel/OptionLabel";
import { useMergeClasses } from "../useMergeClasses";

import { zeffySwitchClasses, StyledSwitch } from "./styles";
import { SwitchProps } from "./types";

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  (
    { alignContent, disabled, vibe = "brand", classes: externalClasses, label, className, tooltipText, ...rest },
    ref
  ) => {
    const currentInputVibe = useCurrentInputVibe();
    const classes = useMergeClasses(zeffySwitchClasses, externalClasses);

    return (
      <OptionLabel
        alignContent={alignContent}
        vibe={currentInputVibe}
        disabled={disabled}
        label={label}
        size="medium"
        tooltipText={tooltipText}
      >
        <StyledSwitch
          {...rest}
          classes={{
            root: classNames(className, classes.root, classes[vibe], { [classes.rootDisabled]: disabled }),
            checked: classes.checked,
            track: classes.track,
            thumb: classes.thumb,
            switchBase: classes.switchBase,
            disabled: classes.disabled,
          }}
          disabled={disabled}
          data-test={rest["data-test"] || "switch"}
          disableRipple
          ref={ref}
          color="primary"
        />
      </OptionLabel>
    );
  }
);

Switch.displayName = "Switch";
