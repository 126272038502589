import * as React from "react";

import { Locales, getField, getLocalesFromIsoLocale, Undefinable } from "@simplyk/common";
import { Maybe } from "graphql/jsutils/Maybe";
import { useRouter } from "next/router";

import { TicketingOutput, TicketingFieldOutput, DonationFormOutput, DonationFormFieldOutput } from "@/types/trpc";

const useParsedLocale = (): Locales => {
  const { locale } = useRouter();
  return React.useMemo(() => getLocalesFromIsoLocale(locale as Locales), [locale]);
};

export const useDonationFormTranslationField = (donationForm: DonationFormOutput) => {
  const locale = useParsedLocale();
  const donationFormField = donationForm?.donationFormFields && getField(donationForm?.donationFormFields, locale);

  return { donationFormField };
};

export const useTicketingTranslationField = (ticketing: TicketingOutput) => {
  const locale = useParsedLocale();
  const ticketingField = ticketing?.ticketingFields && getField(ticketing.ticketingFields, locale);

  return { ticketingField };
};

const isDonationForm = (form: DonationFormOutput | TicketingOutput): form is DonationFormOutput => {
  return (form as DonationFormOutput).donationFormFields !== undefined;
};

export const useTranslationField = (
  form: Undefinable<DonationFormOutput | TicketingOutput>
): {
  field: { locale: Locales; id: string; title: string; donationFormAmounts?: { id: string }[] | null } | undefined;
  fieldLength: number | undefined;
  fields: Maybe<DonationFormFieldOutput[]> | TicketingFieldOutput[] | undefined;
  postTransactionUrl?: string | null;
  chequeDescription?: string | null;
} => {
  const locale = useParsedLocale();

  if (form && isDonationForm(form)) {
    const fields = form.donationFormFields;
    const field = getField(fields, locale);
    const fieldLength = fields?.length;
    const postTransactionUrl = field?.postDonationUrl;
    const chequeDescription = field?.chequeDescription;
    return { fieldLength, fields, field, postTransactionUrl, chequeDescription };
  }

  const fields = form?.ticketingFields;
  const field = getField(fields, locale);
  const fieldLength = fields?.length;
  const postTransactionUrl = field?.postTransactionUrl;
  const chequeDescription = field?.chequeDescription;
  return { fieldLength, fields, field, postTransactionUrl, chequeDescription };
};
