import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertSessionMutationVariables = Types.Exact<{
  sessionInput: Types.UpsertSessionInput;
}>;


export type UpsertSessionMutation = { __typename?: 'Mutation', upsertSession: { __typename?: 'SessionResponseObject', object?: { __typename?: 'SessionObject', id: string } | null } };


export const UpsertSessionDocument = gql`
    mutation upsertSession($sessionInput: UpsertSessionInput!) {
  upsertSession(sessionInput: $sessionInput) {
    object {
      id
    }
  }
}
    `;
export type UpsertSessionMutationFn = Apollo.MutationFunction<UpsertSessionMutation, UpsertSessionMutationVariables>;

/**
 * __useUpsertSessionMutation__
 *
 * To run a mutation, you first call `useUpsertSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSessionMutation, { data, loading, error }] = useUpsertSessionMutation({
 *   variables: {
 *      sessionInput: // value for 'sessionInput'
 *   },
 * });
 */
export function useUpsertSessionMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSessionMutation, UpsertSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertSessionMutation, UpsertSessionMutationVariables>(UpsertSessionDocument, options);
      }
export type UpsertSessionMutationHookResult = ReturnType<typeof useUpsertSessionMutation>;
export type UpsertSessionMutationResult = Apollo.MutationResult<UpsertSessionMutation>;
export type UpsertSessionMutationOptions = Apollo.BaseMutationOptions<UpsertSessionMutation, UpsertSessionMutationVariables>;