import { FeatureFlagTreatmentObject, FeatureFlagName } from "../gql/gql-types";

export const getDonationFormFeatureFlagTreatment = (
  featureFlagTreatments: FeatureFlagTreatmentObject[] | null | undefined,
  treatment: FeatureFlagName
): boolean => {
  return Boolean(
    featureFlagTreatments?.find((featureFlagTreatment) => featureFlagTreatment.name === treatment)?.treatment
  );
};
