/* eslint-disable react/jsx-no-bind */
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { Box, Grid, ThemeProvider, Stack } from "@mui/material";
import { DashboardRoutes, FormStatus } from "@simplyk/common";
import Link from "next/link";
import { enqueueSnackbar } from "notistack";

import { AutoHideDuration } from "../../../constants/Notistack";
import { FrontendFormContext } from "../../../contexts/FrontendFormContext";
import { getEditFormLink } from "../../../features/CampaignHub/helper/getEditFormLink";
import { useBackToFormTable } from "../../../features/LiveFormEditor/Navigation/useBackToFormTabs";
import { getDashboardLink } from "../../../helpers/link-to-dashboard";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useTranslate } from "../../../hooks/useTranslate";
import { OrganizationRoutes } from "../../../routes/routes";
import { zeffyTheme } from "../../../theme/theme";
import { ButtonCopy } from "../../ButtonCopy/ButtonCopy";
import { Button } from "../../design-system/Button";
import { Icon } from "../../design-system/Icon";
import { Typography } from "../../design-system/Typography";
import { ShareFormModal } from "../../ShareFormModal/ShareFormModal";
import { NotificationBannerHeightContext } from "../NotificationBannerHeightContext";
import { useSyncNotificationBannerHeight } from "../useSyncNotificationBannerHeight";

import { SwitchFormVersion } from "./SwitchFormVersion";
import { useShouldDisplayFormOwnerBanner } from "./useShouldDisplayFormOwnerBanner";

import { ChevronUp, Info, Reveal as Eye } from "@/icons/outlined";

const EXPAND_BUTTON_WIDTH = 48;

export const FormOwnerBanner: FC = () => {
  const { isSmallScreen } = useMediaQuery();
  const { t } = useTranslate();
  const { formData, formType } = useContext(FrontendFormContext);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const openShareModal = () => setIsShareModalOpen(true);
  const closeShareModal = () => setIsShareModalOpen(false);

  const { isExpanded, setIsExpanded } = useContext(NotificationBannerHeightContext);
  const containerRef = useSyncNotificationBannerHeight();
  const height = containerRef.current?.offsetHeight || 0;
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setIsExpanded(true);
    return () => setIsExpanded(false);
  }, [setIsExpanded]);

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const buttonSize = isSmallScreen ? "small" : "medium";
  const buttonFullWidth = isSmallScreen;

  const formOrg = formData.organization;
  const isPaymentReady = formOrg?.isStripeCustomAccountActive;

  const { backToFormTableUrl } = useBackToFormTable();

  const editFormLink = getEditFormLink({
    form: {
      id: formData.id,
      formType,
      status: FormStatus.Active,
    },
    isSmallScreen,
  });

  const shouldDisplayAdditionalStripeInfo = !isPaymentReady;

  // When a non logged in user creates a P2P campaign, we store the formId and token in local storage
  // And we use it to display the edit and copy share link buttons
  const { isPeerToPeerCreator } = useShouldDisplayFormOwnerBanner();

  const storedData: { formId: string; token: string } = JSON.parse(
    localStorage.getItem("peer-to-peer-form-data") || "{}"
  );
  const token = storedData.token;

  const onCopy = useCallback(() => {
    enqueueSnackbar(t("donationForm", "linkCopied"), {
      vibe: "positive",
      hideClose: true,
      autoHideDuration: AutoHideDuration.fast,
    });
  }, [t]);

  const editLink = getDashboardLink(
    `${DashboardRoutes.FundraiserFormsEdit.replace(":id", formData.id)}&token=${token}`
  );
  const formLink = window.location.href;

  const editCampaignButton = useMemo(
    () => (
      <Button variant="outlined" vibe="neutral" href={editLink} size={buttonSize} fullWidth={buttonFullWidth}>
        {t("donationForm", "campaignIsLiveDialog.button.editCampaign")}
      </Button>
    ),
    [editLink, buttonSize, buttonFullWidth, t]
  );

  const copyCampaignLinkButton = useMemo(
    () => (
      <ButtonCopy
        variant="filled"
        vibe="positive"
        onClick={onCopy}
        toCopy={formLink}
        copyText={t("donationForm", "campaignIsLiveDialog.button.copyShareLink")}
        size={buttonSize}
        fullWidth={buttonFullWidth}
      />
    ),
    [buttonSize, buttonFullWidth, t, onCopy, formLink]
  );

  const buttons = useMemo(() => {
    if (isSmallScreen) {
      if (isPaymentReady) {
        return (
          <Grid container columnSpacing={2} sx={{ marginTop: zeffyTheme.spacing(1.5) }}>
            <Grid item xs={6}>
              {isPeerToPeerCreator ? (
                editCampaignButton
              ) : (
                <Button variant="outlined" href={backToFormTableUrl} size={buttonSize} fullWidth={buttonFullWidth}>
                  {t("common", "backToDashboard")}
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              {isPeerToPeerCreator ? (
                copyCampaignLinkButton
              ) : (
                <Button variant="filled" onClick={openShareModal} size={buttonSize} fullWidth={buttonFullWidth}>
                  {t("common", "shareForm")}
                </Button>
              )}
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid container columnSpacing={2} sx={{ marginTop: zeffyTheme.spacing(1.5) }}>
            <Grid item xs={6}>
              <Button variant="outlined" href={editFormLink} size={buttonSize} fullWidth={buttonFullWidth}>
                {t("common", "editForm")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button variant="filled" href={backToFormTableUrl} size={buttonSize} fullWidth={buttonFullWidth}>
                {t("common", "backToDashboard")}
              </Button>
            </Grid>
          </Grid>
        );
      }
    } else {
      if (isPaymentReady) {
        return (
          <Stack direction="row" spacing={2}>
            {isPeerToPeerCreator ? (
              editCampaignButton
            ) : (
              <Button variant="outlined" href={backToFormTableUrl} size={buttonSize} fullWidth={buttonFullWidth}>
                {t("common", "backToDashboard")}
              </Button>
            )}
            {isPeerToPeerCreator ? (
              copyCampaignLinkButton
            ) : (
              <Button variant="filled" onClick={openShareModal} size={buttonSize} fullWidth={buttonFullWidth}>
                {t("common", "shareForm")}
              </Button>
            )}
          </Stack>
        );
      } else {
        return (
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" href={editFormLink} size={buttonSize} fullWidth={buttonFullWidth}>
              {t("common", "editForm")}
            </Button>
            <Button variant="filled" href={backToFormTableUrl} size={buttonSize} fullWidth={buttonFullWidth}>
              {t("common", "backToDashboard")}
            </Button>
          </Stack>
        );
      }
    }
  }, [
    isSmallScreen,
    isPaymentReady,
    isPeerToPeerCreator,
    editCampaignButton,
    backToFormTableUrl,
    buttonSize,
    buttonFullWidth,
    t,
    copyCampaignLinkButton,
    editFormLink,
  ]);

  return (
    <ThemeProvider theme={zeffyTheme}>
      <ShareFormModal
        formId={formData.id}
        formType={formType}
        isOpen={isShareModalOpen}
        onClose={closeShareModal}
        amplitudePayload={{ location: "form_donator_view" }}
        mobileAdditionalStyle={{ zIndex: 10000 }}
        showViewMyFormButton={false}
      />

      <Box
        sx={{
          position: "sticky",
          top: isExpanded ? 0 : `-${height}px`,
          zIndex: 1000,
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: zeffyTheme.palette.surface.neutral.white,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderColor: zeffyTheme.palette.border.form.quiet,
          paddingY: zeffyTheme.spacing(isSmallScreen ? 1.5 : 2),
          paddingX: zeffyTheme.spacing(isSmallScreen ? 2 : 6),
          marginTop: isExpanded ? 0 : `-${height}px`,
          transition: "margin 0.3s ease-out, top 0.3s ease-out",
        }}
        ref={containerRef}
        data-test="form-owner-banner"
      >
        <Box flex={1}>
          <Grid container spacing={2}>
            <Grid item>
              <Grid container spacing={1}>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Icon>
                    <Eye />
                  </Icon>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">{t("common", "adminView")}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item margin="auto">
              <SwitchFormVersion formObject={formData} formType={formType} />
            </Grid>
          </Grid>

          {/* Additional Stripe Info */}
          {shouldDisplayAdditionalStripeInfo && (
            <Box display="flex" alignItems="center" marginTop={1}>
              {!isSmallScreen && (
                <Icon vibe="text-brand-intense" size="small">
                  <Info />
                </Icon>
              )}
              <Typography variant="body2" vibe="text-brand-intense" sx={{ marginLeft: 0.4, textAlign: "center" }}>
                {t("common", "linkBankAccountPrompt1")}{" "}
                <Link href={OrganizationRoutes.Bank}>{t("common", "linkBankAccountPrompt2")}</Link>.
              </Typography>
            </Box>
          )}
        </Box>

        {buttons}

        {/* Expand button */}
        <Box
          ref={buttonRef}
          sx={{
            position: "absolute",
            left: `calc(50% - ${EXPAND_BUTTON_WIDTH / 2}px)`,
            bottom: `-${buttonRef?.current?.offsetHeight || 0}px`,
          }}
          data-test="form-owner-banner-expand-button"
        >
          <Button
            variant="text"
            vibe="neutral"
            iconButton
            size="small"
            sx={{
              zIndex: 200,
              width: `${EXPAND_BUTTON_WIDTH}px`,
              backgroundColor: zeffyTheme.palette.surface.neutral.white,
              border: `1px solid ${zeffyTheme.palette.border.form.quiet}`,
              borderRadius: "0 0 8px 8px !important",
            }}
            onClick={handleExpandClick}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "transform 0.3s ease-out",
                transform: isExpanded ? "rotate(0deg)" : "rotate(180deg)",
              }}
            >
              <ChevronUp />
            </Box>
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
