import { ParsedUrlQuery } from "querystring";

import {
  FormType,
  Locales,
  MembershipValidityPeriod,
  StripeRecurringInterval,
  convertCountryToCountryCode,
  getInteger,
  Undefinable,
  PaymentMethod,
  SubscriptionRecurrenceInterval,
  CountryCode,
  CommandLang,
  ProductDonationRecurrenceInterval,
} from "@simplyk/common";
import { inferRouterInputs } from "@trpc/server";
import { DateTime } from "luxon";

import { CommandSource, DiscountObject, TicketingFormCategory } from "../../gql/gql-types";
import { serializeProductBids, serializeProductTickets } from "../../helpers/command";
import { serializeAnswers, serializeUtmAnswers } from "../../helpers/customAnswer";
import { getDiscountAmount } from "../../helpers/order";
import { getEligibleAmountTotal, getOrderAmountTotal, getUniquelySelectedRate } from "../../helpers/rates";
import { computeTicketsPrice } from "../../helpers/ticket";
import { AppRouterType } from "../../helpers/trpc";
import { DonationFormPaymentInput } from "../../types/donationForm";
import { UserData } from "../UserPageActionMenu/useUserPageActionMenu";

import { TicketingPaymentInput } from "@/types/ticketing";
import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

type ZodSubmitCommandInput = inferRouterInputs<AppRouterType>["form_submitCommand"];
type RegistrationFundraisingInput = inferRouterInputs<AppRouterType>["form_submitCommand"]["createFundraisingPayload"];

export interface SubmitCommandFormInput {
  paymentInput: DonationFormPaymentInput | TicketingPaymentInput; // All the data from the react form
  user: UserData | null; // User is defined when we add a payment for a known user (from its user page for example)
  formObject: TicketingOutput | DonationFormOutput; // The form object is the form we are using to add a payment
  paymentMethod: Undefinable<PaymentMethod>;
  source: CommandSource;
  formType: FormType;
  routerQuery: ParsedUrlQuery;
  validDiscount: DiscountObject | null;
  commandId: string | null;
  locale: Locales;
  stripePaymentMethodId: string | null;
  session: {
    sessionId: string;
    durationOnPage: number;
  };
  tip: number;
  tipPercentage: number;
  isExpressCheckout?: boolean;
  createFundraisingPayload?: RegistrationFundraisingInput;
  taxDeductionMessageAbcTest?: string;
}

export type SubmitManualCommandFormInput = Omit<SubmitCommandFormInput, "session">;

export const isTicketingData = (
  _formData: DonationFormPaymentInput | TicketingPaymentInput,
  formType: FormType
): _formData is TicketingPaymentInput => {
  return formType === FormType.Ticketing;
};

export const getSubscriptionRecurrenceFromStripe = (
  recurrence?: StripeRecurringInterval | undefined | null
): SubscriptionRecurrenceInterval | null => {
  if (recurrence === StripeRecurringInterval.month) {
    return SubscriptionRecurrenceInterval.Monthly;
  } else if (recurrence === StripeRecurringInterval.year) {
    return SubscriptionRecurrenceInterval.Yearly;
  }

  return null;
};

export const getProductDonationRecurrenceFromStripe = (
  recurrence?: StripeRecurringInterval | undefined | null
): ProductDonationRecurrenceInterval => {
  if (recurrence === StripeRecurringInterval.month) {
    return ProductDonationRecurrenceInterval.Monthly;
  } else if (recurrence === StripeRecurringInterval.year) {
    return ProductDonationRecurrenceInterval.Yearly;
  }

  return ProductDonationRecurrenceInterval.OneTime;
};

export const getStripeSubscriptionRecurrence = (
  recurrence?: ProductDonationRecurrenceInterval | undefined | null
): StripeRecurringInterval | null => {
  if (recurrence === ProductDonationRecurrenceInterval.Monthly) {
    return StripeRecurringInterval.month;
  } else if (recurrence === ProductDonationRecurrenceInterval.Yearly) {
    return StripeRecurringInterval.year;
  }

  return null;
};

interface GetResourceInput {
  ticketingField: { postTransactionUrl?: string | null; title: string };
  selectedOccurrence?: { startUtc?: number | null | Date; endUtc?: number | null | Date } | null;
  ticketing: { logoUrl?: string | null; address?: string | null };
}

export const getEvent = ({ ticketing, ticketingField, selectedOccurrence }: GetResourceInput) => {
  return {
    title: ticketingField.title,
    address: ticketing.address || "",
    endTime: selectedOccurrence?.endUtc ? new Date(selectedOccurrence?.endUtc).getTime() : undefined,
    startTime: selectedOccurrence?.startUtc ? new Date(selectedOccurrence?.startUtc).getTime() : undefined,
  };
};

interface SubmitCommandFormContext {
  displayedFormAmount: number;
  stripeRecurrenceInterval?: StripeRecurringInterval | null;
  isFormV2: boolean;
}

const membershipRecurrenceFromValidityPeriod = {
  [MembershipValidityPeriod.OneMonthAfterPayment]: SubscriptionRecurrenceInterval.Monthly,
  [MembershipValidityPeriod.OneYearAfterPayment]: SubscriptionRecurrenceInterval.Yearly,
  [MembershipValidityPeriod.AtDate]: SubscriptionRecurrenceInterval.Yearly,
  [MembershipValidityPeriod.NoExpiration]: null,
};

export const formatSubmitCommandData = (
  data: SubmitCommandFormInput,
  { displayedFormAmount, stripeRecurrenceInterval, isFormV2 }: SubmitCommandFormContext
): Omit<ZodSubmitCommandInput, "id"> => {
  const {
    paymentInput,
    paymentMethod,
    user,
    formObject,
    formType,
    validDiscount,
    locale,
    stripePaymentMethodId,
    routerQuery,
    session,
    tip,
    tipPercentage,
    taxDeductionMessageAbcTest,
  } = data;

  const currentTip = paymentMethod === PaymentMethod.Cheque ? 0 : tip || 0;

  const firstName = user ? user.firstName : paymentInput.firstName || null;
  const lastName = user ? user.lastName : paymentInput.lastName || null;
  const email = user ? user.email || null : paymentInput.email || null;
  const address = user ? user.details.address || null : paymentInput.address || null;
  const city = user ? user.details.city || null : paymentInput.city || null;
  const postalCode = user ? user.details.postalCode || null : paymentInput.postalCode || null;
  const region = user ? user.details.region || null : paymentInput.region || null;
  const country =
    user && user.details.country
      ? (user.details.country as CountryCode)
      : convertCountryToCountryCode(paymentInput.country || "");

  if (isTicketingData(paymentInput, formType)) {
    const ticketing = formObject as TicketingOutput;
    const isAuction = ticketing.formCategory === TicketingFormCategory.Auction;
    const uniquelySelectedRate =
      ticketing.rates && getUniquelySelectedRate(paymentInput.ticketsPurchased, ticketing.rates);
    const expirationDate =
      (uniquelySelectedRate?.membershipValidityPeriod === MembershipValidityPeriod.AtDate &&
        uniquelySelectedRate.membershipValidityPeriodAtDate &&
        new Date(uniquelySelectedRate.membershipValidityPeriodAtDate)) ||
      null;

    const extraDonation = paymentInput.extraDonation ? getInteger(paymentInput.extraDonation as number) : 0;
    const productTickets =
      serializeProductTickets(paymentInput.ticketsPurchased, paymentInput.customAnswersOfParticipants) || [];
    const productBids = serializeProductBids(paymentInput.ticketsPurchased, paymentInput.customAnswersOfParticipants);

    const eligibleAmount = getEligibleAmountTotal(
      paymentInput.ticketsPurchased,
      ticketing,
      extraDonation || 0,
      validDiscount
    );
    const ticketsPrice = ticketing.rates ? computeTicketsPrice(ticketing.rates, paymentInput.ticketsPurchased) : 0;

    const discountAmount =
      validDiscount && paymentInput.ticketsPurchased && ticketing.rates
        ? getDiscountAmount(validDiscount, ticketsPrice)
        : 0;
    const answersOfOrder = serializeAnswers(paymentInput.customAnswersOfOrder);

    const transferableAmount =
      (ticketing.rates
        ? getOrderAmountTotal({
            ticketsPurchased: paymentInput.ticketsPurchased,
            rates: ticketing.rates,
            extraDonation,
            isAuction,
            discount: validDiscount,
          })
        : 0) || 0;

    const automaticRenewal =
      uniquelySelectedRate && productTickets.every((productTicket) => Boolean(productTicket.automaticRenewal));

    const totalAmount = isAuction ? transferableAmount : transferableAmount + currentTip;

    return {
      formType: FormType[formType],
      formId: formObject.id,
      formLang: paymentInput.emailLanguage ? CommandLang[paymentInput.emailLanguage] : CommandLang[locale],
      locale,
      organizationId: ticketing?.organization?.id as string,
      firstName,
      lastName,
      email,
      userId: user?.id || null,
      address,
      city,
      postalCode,
      region,
      country,
      isCorporate: paymentInput.giveAsOrganism ?? false,
      corporationName: paymentInput.companyName || null,
      productsAmount: getInteger(ticketsPrice || 0),
      tipAmount: getInteger(currentTip),
      tipPercentage,
      totalAmount: getInteger(totalAmount || 0),
      discountAmount: getInteger(discountAmount || 0),
      eligibleAmount: getInteger(eligibleAmount || 0),
      extraDonation: getInteger(extraDonation || 0),
      discountId: paymentInput.discountId || null,
      paymentMethod,
      stripePaymentMethodId,
      stripeProductId: formObject.stripeProductId,
      recurrenceInterval:
        automaticRenewal && uniquelySelectedRate?.membershipValidityPeriod
          ? membershipRecurrenceFromValidityPeriod[uniquelySelectedRate.membershipValidityPeriod]
          : null,
      source: data.source,
      productTickets,
      productBids,
      session: session.sessionId ? session : null,
      sessionId: session.sessionId,
      createManualInput: {
        createdAtUtc: paymentInput.donationDate,
        shouldGenerateReceipt: Boolean(paymentInput.generateReceipt),
        shouldSendNotificationEmail: Boolean(paymentInput.sendNotificationEmail),
        receiptAnnotation: paymentInput.receiptAnnotation,
      },
      answers: answersOfOrder,
      ticketingOccurrenceId: paymentInput.occurrenceId || null,
      expirationDate,
      userTimezone: DateTime.local().zoneName || "UTC",
      createFundraisingPayload: data.createFundraisingPayload || null,
      taxDeductionMessageAbcTest: taxDeductionMessageAbcTest || null,
      isFormV2,
      productDonation: null,
      experimentValues: null,
    };
  } else {
    const donationFormAnswersWithUtm = serializeAnswers(paymentInput.answers).concat(serializeUtmAnswers(routerQuery));
    const transferableAmount = displayedFormAmount;
    const totalAmount = (displayedFormAmount || 0) + currentTip;

    return {
      formType: FormType[formType],
      formId: formObject.id,
      formLang: paymentInput.emailLanguage ? CommandLang[paymentInput.emailLanguage] : CommandLang[locale],
      locale,
      organizationId: formObject?.organizationId as string,
      firstName,
      lastName,
      email,
      userId: user?.id || null,
      address,
      city,
      postalCode,
      region,
      country,
      isCorporate: paymentInput.giveAsOrganism ?? false,
      corporationName: paymentInput.giveAsOrganism ? paymentInput.companyName || null : null,
      productsAmount: getInteger(transferableAmount || 0),
      tipAmount: getInteger(currentTip),
      tipPercentage: 0,
      totalAmount: getInteger(totalAmount || 0),
      discountAmount: 0,
      eligibleAmount: getInteger(transferableAmount || 0),
      extraDonation: 0,
      discountId: null,
      paymentMethod,
      stripePaymentMethodId,
      stripeProductId: formObject.stripeProductId,
      recurrenceInterval: getSubscriptionRecurrenceFromStripe(stripeRecurrenceInterval),
      source: data.source,
      productDonation: {
        amount: transferableAmount,
        isAnonymous: !paymentInput.isPublicDonation,
        isInHonour: paymentInput.inHonourOf ?? false,
        inHonourName: paymentInput.inHonourName || null,
        sendInHonourEmail: paymentInput.notifySomeone ?? false,
        inHonourEmailAddress: paymentInput.inHonourNotifyEmail || null,
        inHonourEmailBody: paymentInput.inHonourMessage || null,
        recurrenceInterval:
          getProductDonationRecurrenceFromStripe(stripeRecurrenceInterval) || ProductDonationRecurrenceInterval.OneTime,
        annotation: paymentInput.annotation || null,
        thankYouComment: null,
      },
      session: session.sessionId ? session : null,
      sessionId: session.sessionId,
      createManualInput: {
        createdAtUtc: paymentInput.donationDate,
        shouldGenerateReceipt: Boolean(paymentInput.generateReceipt),
        shouldSendNotificationEmail: Boolean(paymentInput.sendNotificationEmail),
        receiptAnnotation: paymentInput.receiptAnnotation,
      },
      answers: donationFormAnswersWithUtm,
      userTimezone: DateTime.local().zoneName || "UTC",
      createFundraisingPayload: data.createFundraisingPayload || null,
      ticketingOccurrenceId: null,
      productTickets: null,
      productBids: null,
      experimentValues: null,
      isFormV2: false,
      taxDeductionMessageAbcTest: null,
    };
  }
};
