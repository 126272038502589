import * as React from "react";

import { SelectChangeEvent } from "@mui/material/Select";
import {
  AvailablePaymentCurrency,
  FormType,
  Locales,
  StripeRecurringInterval,
  Undefinable,
  Emptyable,
  PaymentMethod,
} from "@simplyk/common";

import { SelectedTip } from "../components/PaymentProcessor/hooks/useComputeTip";
import { AmplitudeContext } from "../components/PaymentProcessor/type";
import {
  OrganizationObject,
  CommandObject,
  TipSuggestionObject,
  TicketingFormCategory,
  DonationFormCategory,
} from "../gql/gql-types";

import { PaymentMode } from "@/helpers/stripe";
import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

export interface FrontendFormContextValues {
  formData: DonationFormOutput | TicketingOutput;
  organization: OrganizationObject;
  commandId: CommandObject["id"];
  formType: FormType;
  category: TicketingFormCategory | DonationFormCategory;
  themeColor?: Emptyable<string>;
  translationLocales: Locales[]; // Languages the form can be translated to, excluding the current language.
  isOnboarding: boolean;
  field: { locale: Locales; id: string; title: string } | undefined;
  isEmbed: boolean;
  isFundraiser: boolean;
  isFormValid: boolean;
  setIsFormValid: React.Dispatch<React.SetStateAction<boolean>>;
  isFormDirty: boolean;
  setIsFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
  shouldDisplayLogin: boolean;
  shouldDisplayLanguageSelect: boolean;
  formAmplitudeContext: AmplitudeContext;
  selectedPaymentMethod: Undefinable<PaymentMethod>;
  setSelectedPaymentMethod: (paymentMethod: Undefinable<PaymentMethod>, complete?: boolean) => void;
  currency: AvailablePaymentCurrency | undefined;
  displayedFormAmount: number;
  setDisplayedFormAmount: React.Dispatch<React.SetStateAction<number>>;
  displayedFormAmountWithTip: number;
  tip: number;
  suggestedTips: [TipSuggestionObject, TipSuggestionObject, TipSuggestionObject] | undefined;
  selectedTip: SelectedTip;
  isSuggestedTipsAmount: boolean;
  handleChangeTips: (event: SelectChangeEvent<unknown>) => void;
  handleChangeFreeTips: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  resetTipToCoverBankCharge: () => void;
  freeTips: number | "";
  updateFreeTipAmount: (amount: number) => void;
  fees: number;
  formattedFees: string | undefined;
  displayTipSelection: boolean;
  stripeRecurrenceInterval: Undefinable<StripeRecurringInterval | null>;
  setStripeRecurrenceInterval: React.Dispatch<React.SetStateAction<StripeRecurringInterval | null>>;
  isMembershipV2: boolean;
  isArchived: boolean;
  paymentRequest: PaymentRequest | null;
  setPaymentRequest: React.Dispatch<React.SetStateAction<PaymentRequest | null>>;
  setCanUsePaymentRequest: React.Dispatch<React.SetStateAction<boolean>>;
  canUsePaymentRequest: boolean;
  areBankPaymentMethodsAllowed: boolean;
  allowedPaymentMethods: PaymentMethod[];
  postTransactionUrl: string | null | undefined;
  prioritizeBankPayment: boolean;
  selectedPaymentMethodIsBank: boolean;
  selectedPaymentMethodIsCard: boolean;
  chequeDescription: string | null | undefined;
  showProceedButton: boolean;
  isExpressCheckoutLoading: boolean;
  displayExpressCheckout: boolean;
  handleSetSupportsExpressCheckout: (value: boolean) => void;
  isSubmitting: boolean;
  setIsSubmitting: (value: boolean) => void;
  expressCheckoutIsSelected: boolean;
  showTopBillingInfo: boolean;
  showBottomBillingInfo: boolean;
  expressCheckoutDisabled: boolean;
  paymentMode: PaymentMode;
  isAuction: boolean;
  shouldSendTipPercentage: boolean;
  getTipChoice: (value: string) => number | "custom";
  logAmplitudeTipsChanged: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  displayAddressQuestion: boolean;
  isPreviewTemplateMode?: boolean;
  isPreview?: boolean;
  generateETicket: boolean;
  defaultSelectedPaymentMethod: PaymentMethod | undefined;
  isFreeTicketing: boolean;
  isPaymentDisabled?: boolean;
}

const defaultValues = {
  formData: {} as DonationFormOutput,
  organization: {} as OrganizationObject,
  commandId: "",
  formType: FormType.DonationForm,
  category: DonationFormCategory.DonationForm,
  themeColor: "",
  translationLocales: [],
  isOnboarding: false,
  field: undefined,
  isEmbed: false,
  isFundraiser: false,
  isFormValid: false,
  setIsFormValid: () => {},
  isFormDirty: false,
  setIsFormDirty: () => {},
  shouldDisplayLogin: false,
  shouldDisplayLanguageSelect: false,
  formAmplitudeContext: {} as never,
  selectedPaymentMethod: undefined,
  selectedPaymentMethodIsComplete: false,
  setSelectedPaymentMethod: () => {},
  currency: undefined,
  amplitudeContext: undefined,
  displayedFormAmount: 0,
  setDisplayedFormAmount: () => {},
  displayedFormAmountWithTip: 0,
  tip: 0,
  suggestedTips: undefined,
  selectedTip: {},
  isSuggestedTipsAmount: false,
  handleChangeTips: () => {},
  handleChangeFreeTips: () => {},
  resetTipToCoverBankCharge: () => {},
  freeTips: "" as const,
  updateFreeTipAmount: () => {},
  fees: 0,
  formattedFees: undefined,
  displayTipSelection: true,
  stripeRecurrenceInterval: undefined,
  setStripeRecurrenceInterval: () => {},
  isMembershipV2: false,
  isArchived: false,
  paymentRequest: null,
  setPaymentRequest: () => {},
  canUsePaymentRequest: false,
  setCanUsePaymentRequest: () => {},
  areBankPaymentMethodsAllowed: false,
  allowedPaymentMethods: [],
  postTransactionUrl: null,
  prioritizeBankPayment: false,
  selectedPaymentMethodIsBank: false,
  selectedPaymentMethodIsCard: false,
  chequeDescription: null,
  showProceedButton: false,
  displayExpressCheckout: false,
  handleSetSupportsExpressCheckout: () => {},
  isExpressCheckoutLoading: false,
  isSubmitting: false,
  setIsSubmitting: () => {},
  expressCheckoutIsSelected: false,
  showTopBillingInfo: false,
  showBottomBillingInfo: false,
  expressCheckoutDisabled: false,
  paymentMode: "payment" as const,
  isAuction: false,
  shouldSendTipPercentage: false,
  getTipChoice: () => 0,
  logAmplitudeTipsChanged: () => {},
  displayAddressQuestion: false,
  isPreviewTemplateMode: false,
  isPreview: false,
  generateETicket: false,
  defaultSelectedPaymentMethod: undefined,
  isFreeTicketing: false,
  isPaymentDisabled: false,
};

export const FrontendFormContext = React.createContext<FrontendFormContextValues>(defaultValues);
