import { useRouter } from "next/router";

import { OrganizationRoutes } from "../../../routes/routes";
import { useCampaignFeatureFlag } from "../../CampaignHub/useCampaignFeatureFlag";

export const useBackToFormTable = () => {
  const router = useRouter();
  const { isCampaignsHubFeatureActivated } = useCampaignFeatureFlag();

  const backToFormTableUrl = isCampaignsHubFeatureActivated
    ? OrganizationRoutes.CampaignHub
    : OrganizationRoutes.FormTable;

  const backToFormTable = async () => {
    await router.push(backToFormTableUrl);
  };

  return { backToFormTable, backToFormTableUrl };
};
