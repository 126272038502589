import {
  StripeRecurringInterval,
  PaymentMethod as CommonPaymentMethod,
  isAchPadAllowed,
  Undefinable,
  OrganizationCountry,
} from "@simplyk/common";

import { FormType, PaymentMethod, TicketingOccurrenceObject } from "../gql/gql-types";

import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

interface ListPaymentMethodsArgs {
  orgCardMaximumAmount: number;
  formData: DonationFormOutput | TicketingOutput;
  selectedOccurrence?: TicketingOccurrenceObject | null;
  areBankPaymentMethodsAllowed: boolean;
  organizationCountry: OrganizationCountry;
  totalAmount: number;
  formType?: FormType;
  stripeRecurrenceInterval?: StripeRecurringInterval | null;
  isAuction?: boolean;
  isStripeCustomAccountActive: boolean;
  isCashappEnabled: boolean;
  isPreviewTemplateMode?: boolean;
}

export const ONE_TIME_PAD_MIN = 1_000_00;
const CARD_MAX = 10_000_00;

export const listPaymentMethods = (args: ListPaymentMethodsArgs): CommonPaymentMethod[] => {
  if (!args.formData) {
    return [];
  }
  const cardMaximumAmount = Math.min(CARD_MAX, args.orgCardMaximumAmount);
  const isYearlyDonation =
    args.formType === FormType.DonationForm && args.stripeRecurrenceInterval === StripeRecurringInterval.year;

  const { isPadAllowed, isAchAllowed } = isAchPadAllowed({
    startUtc: args.selectedOccurrence?.startUtc ? new Date(args.selectedOccurrence.startUtc) : null,
    endUtc: args.selectedOccurrence?.endUtc ? new Date(args.selectedOccurrence.endUtc) : null,
  });

  const isPadDisabled = !isPadAllowed && !args.isAuction;
  const isAchDisabled = !isAchAllowed && !args.isAuction;

  const paymentMethodsAllowed = {
    [CommonPaymentMethod.Card]: !isYearlyDonation && args.totalAmount < cardMaximumAmount,
    [CommonPaymentMethod.Pad]: isBankDebitAllowed({
      currentAmount: args.totalAmount,
      cardMaximumAmount,
      isYearlyDonation,
      isCountryAllowed: args.organizationCountry === OrganizationCountry.Canada,
      isDisabled: !args.areBankPaymentMethodsAllowed || isPadDisabled,
      isStripeCustomAccountActive: Boolean(args.isStripeCustomAccountActive || args.isPreviewTemplateMode),
    }),
    [CommonPaymentMethod.Ach]: isBankDebitAllowed({
      currentAmount: args.totalAmount,
      cardMaximumAmount,
      isYearlyDonation,
      isCountryAllowed: args.organizationCountry === OrganizationCountry.UnitedStates,
      isDisabled: !args.areBankPaymentMethodsAllowed || isAchDisabled,
      isStripeCustomAccountActive: Boolean(args.isStripeCustomAccountActive || args.isPreviewTemplateMode),
    }),
    [CommonPaymentMethod.ApplePayOrGooglePay]: !isYearlyDonation && args.totalAmount < cardMaximumAmount,
    [CommonPaymentMethod.Cheque]:
      ((args.formData as DonationFormOutput).allowCheque &&
        !args.stripeRecurrenceInterval &&
        args.totalAmount >= ONE_TIME_PAD_MIN &&
        !args.isAuction) ||
      args.isPreviewTemplateMode,
    [CommonPaymentMethod.Cash]: (args.formData as DonationFormOutput).allowCheque && args.isAuction,
    [CommonPaymentMethod.CashApp]:
      args.isCashappEnabled && args.organizationCountry === OrganizationCountry.UnitedStates,
  };

  return Object.entries(paymentMethodsAllowed)
    .filter(([, isAllowed]) => isAllowed)
    .map(([method]) => method as CommonPaymentMethod);
};

const isBankDebitAllowed = ({
  currentAmount,
  cardMaximumAmount,
  isCountryAllowed,
  isYearlyDonation,
  isDisabled,
  isStripeCustomAccountActive,
}: {
  currentAmount: number;
  cardMaximumAmount: number;
  isYearlyDonation: boolean;
  isCountryAllowed: boolean;
  isDisabled: boolean;
  isStripeCustomAccountActive: boolean;
}): boolean => {
  if (!isStripeCustomAccountActive) {
    return false;
  }
  if (!isCountryAllowed || isDisabled) {
    return false;
  }

  // Always display bank debit method for yearly donations as it is the only payment method allowed.
  if (isYearlyDonation) {
    return true;
  }

  // In the case cards have been disabled, we show the bank debit option even if the minimum amount is not reached.
  if (cardMaximumAmount < currentAmount) {
    return true;
  }

  return currentAmount >= 0;
};

export const isPaymentMethodAlwaysComplete = (paymentMethod: Undefinable<CommonPaymentMethod>): boolean => {
  return paymentMethod !== CommonPaymentMethod.Ach;
};

export const doesPaymentMethodAllowTip = (paymentMethod: CommonPaymentMethod | undefined): boolean => {
  return !paymentMethod || ![CommonPaymentMethod.Cheque, CommonPaymentMethod.Cash].includes(paymentMethod);
};

const commonToGqlPaymentMethodMap: Record<CommonPaymentMethod, PaymentMethod> = {
  [CommonPaymentMethod.Card]: PaymentMethod.Card,
  [CommonPaymentMethod.Pad]: PaymentMethod.Pad,
  [CommonPaymentMethod.Ach]: PaymentMethod.Ach,
  [CommonPaymentMethod.ApplePayOrGooglePay]: PaymentMethod.ApplePayOrGooglePay,
  [CommonPaymentMethod.Cash]: PaymentMethod.Cash,
  [CommonPaymentMethod.Cheque]: PaymentMethod.Cheque,
  [CommonPaymentMethod.CashApp]: PaymentMethod.CashApp,
  [CommonPaymentMethod.Free]: PaymentMethod.Free,
  [CommonPaymentMethod.InKind]: PaymentMethod.InKind,
  [CommonPaymentMethod.Stocks]: PaymentMethod.Stocks,
  [CommonPaymentMethod.Other]: PaymentMethod.Other,
  [CommonPaymentMethod.TapToPay]: PaymentMethod.TapToPay,
  [CommonPaymentMethod.Unknown]: PaymentMethod.Other,
  [CommonPaymentMethod.Transfer]: PaymentMethod.Other,
  [CommonPaymentMethod.Manual]: PaymentMethod.Other,
};

export const convertCommonPaymentMethodToGqlPaymentMethod = (
  commonPaymentMethod: CommonPaymentMethod
): PaymentMethod => {
  const gqlPaymentMethod = commonToGqlPaymentMethodMap[commonPaymentMethod];
  return gqlPaymentMethod ?? PaymentMethod.Other;
};

const gqlToCommonPaymentMethodMap: Record<PaymentMethod, CommonPaymentMethod> = {
  [PaymentMethod.Card]: CommonPaymentMethod.Card,
  [PaymentMethod.Pad]: CommonPaymentMethod.Pad,
  [PaymentMethod.Ach]: CommonPaymentMethod.Ach,
  [PaymentMethod.ApplePayOrGooglePay]: CommonPaymentMethod.ApplePayOrGooglePay,
  [PaymentMethod.Cash]: CommonPaymentMethod.Cash,
  [PaymentMethod.Cheque]: CommonPaymentMethod.Cheque,
  [PaymentMethod.CashApp]: CommonPaymentMethod.CashApp,
  [PaymentMethod.Free]: CommonPaymentMethod.Free,
  [PaymentMethod.InKind]: CommonPaymentMethod.InKind,
  [PaymentMethod.Stocks]: CommonPaymentMethod.Stocks,
  [PaymentMethod.Other]: CommonPaymentMethod.Other,
  [PaymentMethod.TapToPay]: CommonPaymentMethod.TapToPay,
  [PaymentMethod.Manual]: CommonPaymentMethod.Manual,
  [PaymentMethod.Transfer]: CommonPaymentMethod.Transfer,
  [PaymentMethod.Unknown]: CommonPaymentMethod.Unknown,
};

export const convertGqlPaymentMethodToCommonPaymentMethod = (gqlPaymentMethod: PaymentMethod): CommonPaymentMethod => {
  const commonPaymentMethod = gqlToCommonPaymentMethodMap[gqlPaymentMethod];
  return commonPaymentMethod ?? CommonPaymentMethod.Other;
};
