import { CountryCode, getCountry, Undefinable, Utils } from "@simplyk/common";
import { inferRouterInputs } from "@trpc/server";

import { serializeParticipantAnswers } from "./serializeOrder";
import { AppRouterType } from "./trpc";

import { ParticipantAnswers, TicketPurchase } from "@/types/ticketing";

type CreateProductBidInput = inferRouterInputs<AppRouterType>["form_submitCommand"]["productBids"];
type CreateProductTicketInput = inferRouterInputs<AppRouterType>["form_submitCommand"]["productTickets"];

export const serializeProductTickets = (
  ticketsPurchased: TicketPurchase[] | undefined,
  customAnswersOfParticipants?: ParticipantAnswers[]
): CreateProductTicketInput => {
  const customAnswersOfParticipantsGroupedByRateId = customAnswersOfParticipants
    ? Utils.groupBy(customAnswersOfParticipants, (answer) => answer.rateId)
    : {};
  return (
    ticketsPurchased?.reduce((array, ticket) => {
      if (ticket.purchasedNumber && ticket.purchasedNumber > 0) {
        const participantAnswers = customAnswersOfParticipantsGroupedByRateId[ticket.rateId];
        const participantAnswersList = participantAnswers
          ?.map((participantAnswer) => serializeParticipantAnswers(participantAnswer.answers, ticket.rateId))
          .filter(Utils.notEmpty)
          .flat();
        const productTicket = {
          quantity: ticket.purchasedNumber,
          rateId: ticket.rateId,
          automaticRenewal: ticket.automaticRenewal ?? false,
          answers: participantAnswersList,
          annotation: ticket.annotation || null,
        };

        array.push(productTicket);
      }

      return array;
    }, [] as NonNullable<CreateProductTicketInput>) || []
  );
};

export const serializeProductBids = (
  ticketsPurchased: TicketPurchase[] | undefined,
  customAnswersOfParticipants?: ParticipantAnswers[]
): CreateProductBidInput => {
  const customAnswersOfParticipantsGroupedByRateId = customAnswersOfParticipants
    ? Utils.groupBy(customAnswersOfParticipants, (answer) => answer.rateId)
    : {};
  return (
    ticketsPurchased?.reduce((array, ticket) => {
      if (ticket.bid) {
        const participantAnswers = customAnswersOfParticipantsGroupedByRateId[ticket.rateId];
        const participantAnswersList = participantAnswers
          ?.map((participantAnswer) => serializeParticipantAnswers(participantAnswer.answers, ticket.rateId))
          .flat();
        const productTicket = {
          amount: ticket.bid,
          rateId: ticket.rateId,
          answers: participantAnswersList,
        };

        array.push(productTicket);
      }

      return array;
    }, [] as NonNullable<CreateProductBidInput>) || []
  );
};

export const convertCountryToCountryCode = (country: Undefinable<string>): Undefinable<CountryCode> => {
  if (!country) {
    return;
  }

  return CountryCode[getCountry(country)?.countryShortCode as keyof typeof CountryCode];
};
