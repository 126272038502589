import { useContext, useEffect } from "react";

import { Undefinable } from "@simplyk/common";
import { useRouter } from "next/router";

import { useLocaleContext } from "../contexts/LocaleContext";
import { SessionContext } from "../contexts/SessionContext";
import { FormType, OrganizationObject } from "../gql/gql-types";
import { setSentryFormTags, setSentryOrganizationTags, setSentryUser } from "../helpers/sentry";

import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

interface FormVisitProps {
  formData: TicketingOutput | DonationFormOutput | undefined;
  formType: Undefinable<FormType>;
}

export const useFormVisited = ({ formData, formType }: FormVisitProps) => {
  const router = useRouter();
  const { sessionId } = useContext(SessionContext);
  const { locale } = useLocaleContext();

  const { email, firstName, lastName } = router.query as {
    amount?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    address?: string;
    city?: string;
    postalCode?: string;
  };

  useEffect(() => {
    const setupSentry = async () => {
      setSentryUser({ firstName, lastName, email, locale, sessionId });
      if (formData?.organization) {
        setSentryOrganizationTags(formData.organization as OrganizationObject);
      }
      if (formData && formType) {
        setSentryFormTags({ id: formData?.id, type: formType });
      }
    };

    setupSentry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
