import { useCallback, useMemo, useState } from "react";

import { PaymentMode } from "../helpers/stripe";

import { useMediaQuery } from "./useMediaQuery";

export const useSupportsExpressCheckout = (paymentMode: PaymentMode) => {
  const { isSmallScreen } = useMediaQuery();

  const isBrowserSafari = useMemo(() => {
    if (typeof window === "undefined") {
      return false;
    }
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }, []);

  // We don't want to show the express checkout button on Safari desktop
  // We don't want to allow express checkout for setup payment method mode
  const expressCheckoutDisabled = (isBrowserSafari && !isSmallScreen) || paymentMode === "setup";

  const [isExpressCheckoutLoading, setIsExpressCheckoutLoading] = useState(expressCheckoutDisabled ? false : true);
  const [supportsExpressCheckout, setSupportsExpressCheckout] = useState(false);
  const [showExpressCheckoutLayout, setShowExpressCheckoutLayout] = useState(false);

  const handleSetSupportsExpressCheckout = useCallback(
    (value: boolean) => {
      // We stop loading regargless of the value
      setIsExpressCheckoutLoading(false);

      setSupportsExpressCheckout(value);

      // If we are showing the express checkout layout, we don't want to revert it
      if (!showExpressCheckoutLayout) {
        setShowExpressCheckoutLayout(value);
      }
    },
    [showExpressCheckoutLayout]
  );

  return {
    expressCheckoutDisabled,
    // If express checkout is disabled, we override all flags to false
    isExpressCheckoutLoading: expressCheckoutDisabled ? false : isExpressCheckoutLoading,
    supportsExpressCheckout: expressCheckoutDisabled ? false : supportsExpressCheckout,
    showExpressCheckoutLayout: expressCheckoutDisabled ? false : showExpressCheckoutLayout,
    handleSetSupportsExpressCheckout,
  };
};
