import { useTheme } from "@mui/material/styles";

import Image from "../Image/Image";

export const HubspotChatbot = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        position: "fixed",
        zIndex: theme.zIndex.hubspot,
        bottom: 0,
        right: 0,
        backgroundColor: theme.palette.primary.main,
        opacity: 0.5,
        width: 100,
        height: 96,
      }}
    >
      <Image
        width={60}
        height={60}
        src="/images/hubspotChatbot.png"
        alt="hubspotChatbot"
        style={{ marginBottom: 16, marginRight: 16, marginTop: 20, marginLeft: 24, cursor: "pointer" }}
      />
    </div>
  );
};
