import { useEffect } from "react";

import { IsoLocale, Locales, getIsoLocaleFromCountry, OrganizationCountry } from "@simplyk/common";
import { useRouter } from "next/router";

import { getNavigatorLocale, getFormRedirectionFromLocaleAndCountry } from "../helpers/locales";

import { useSetIsoLocale } from "./useSetIsoLocale";

export const useLocaleRedirection = ({
  country,
  fields,
}: {
  country?: OrganizationCountry;
  fields?: { locale: Locales }[];
}) => {
  const router = useRouter();
  const { locale: isoLocale, asPath, query, pathname } = router;

  const { setIsoLocale } = useSetIsoLocale();

  // if the page is not a form page (!fields), we do not redirect
  // otherwise, we get the browser locale and redirect to it on first page load
  useEffect(() => {
    if (!fields) {
      return;
    }
    const navigatorIsoLocale = getIsoLocaleFromCountry(country, getNavigatorLocale());
    const formIsoLocale = getFormRedirectionFromLocaleAndCountry({
      country,
      fields,
      isoLocale: navigatorIsoLocale as IsoLocale,
    });
    if (formIsoLocale && isoLocale !== formIsoLocale) {
      setIsoLocale(formIsoLocale);
      return;
    }
    // this check is to redirect only when no locale is specified in the URL (en is the default locale)
    if ((!isoLocale || isoLocale === "en") && navigatorIsoLocale) {
      setIsoLocale(navigatorIsoLocale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, fields, setIsoLocale]);

  const redirectionLocale =
    fields && getFormRedirectionFromLocaleAndCountry({ country, fields, isoLocale: isoLocale as IsoLocale });

  // redirectionLocale is set when the URL locale does not match any translation in the form
  useEffect(() => {
    // this check is to redirect only when a locale is specified in the URL (en is the default locale)
    if (isoLocale !== "en" && redirectionLocale) {
      setIsoLocale(redirectionLocale);
    }
  }, [asPath, isoLocale, pathname, query, redirectionLocale, router, setIsoLocale]);

  return { redirectionLocale };
};
