import { ParsedUrlQuery } from "querystring";

import { Locales, OrganizationCountry, CampaignAction } from "@simplyk/common";
import { inferRouterInputs } from "@trpc/server";
import { v4 } from "uuid";

import { TeamData } from "../components/CampaignModal/teamData";
import { AnswerInput } from "../gql/gql-types";
import { CustomAnswer } from "../types/customQuestion";

import { AppRouterType } from "./trpc";

import { CreateTeamData, TicketingFundraisingData } from "@/types/ticketing";

type RegistrationFundraisingInput = inferRouterInputs<AppRouterType>["form_submitCommand"]["createFundraisingPayload"];

type UserData = {
  firstName: string;
  lastName: string;
  email: string;
};

export const serializeUtmAnswers = (query: ParsedUrlQuery) => {
  const utmQuestions = Object.entries(query)
    .filter(([key, value]) => typeof key === "string" && key.toLowerCase().includes("utm") && value)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return [key, value.join(",")];
      }
      return [key, value];
    }) as string[][];

  return utmQuestions.map(([key, value]) => ({ id: v4(), questionId: "", utm: key, answer: value }));
};

export const serializeAnswers = (answers: CustomAnswer[] | undefined): AnswerInput[] =>
  answers?.map((answer) => serializeAnswer(answer)) || [];

export const serializeAnswer = (answer: CustomAnswer): AnswerInput => {
  return {
    questionId: answer.questionId,
    answer: answer.answers ? answer.answers.join(" ") : null,
    id: answer.id || v4(),
    choiceIds: answer.choiceIds,
  };
};

export const serializeFundraisingInput = (
  input: TeamData,
  user: UserData,
  locale: Locales,
  organizationCountry: OrganizationCountry
): NonNullable<RegistrationFundraisingInput>["inputs"][number] => {
  const firstName = input.sameBuyerInformation ? user.firstName : input.firstName;
  const lastName = input.sameBuyerInformation ? user.lastName : input.lastName;
  const email = input.sameBuyerInformation ? user.email : input.email;

  return {
    name: `${firstName} ${lastName}`,
    firstName,
    lastName,
    email,
    locale,
    logo: input.logo || null,
    target: input.campaignTarget,
    organizationCountry,
    teamToJoinId: input.team || null,
    campaignId: null,
  };
};

export const serializeFundraisingInputs = (
  fundraising: TicketingFundraisingData,
  user: UserData,
  locale: Locales,
  organizationCountry: OrganizationCountry
): NonNullable<RegistrationFundraisingInput>["inputs"] => {
  if (fundraising.type === CampaignAction.CreateTeam && !fundraising.createTeam?.createIndividualFundraising) {
    return [];
  }
  return fundraising?.inputs?.map((input) => serializeFundraisingInput(input, user, locale, organizationCountry)) || [];
};

export const serializeFundraisingCreateTeam = (
  createTeam: CreateTeamData,
  user: UserData,
  locale: Locales,
  organizationCountry: OrganizationCountry
): NonNullable<RegistrationFundraisingInput>["createTeam"] => ({
  name: createTeam.teamName,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  locale,
  logo: createTeam.logo || null,
  target: createTeam.campaignTarget,
  organizationCountry,
  createIndividualFundraising: Boolean(createTeam.createIndividualFundraising),
  campaignId: null,
  teamToJoinId: null,
});

export const serializeFundraising = (
  fundraising: TicketingFundraisingData | undefined,
  user: UserData,
  locale: Locales,
  organizationCountry: OrganizationCountry
): RegistrationFundraisingInput | undefined =>
  fundraising
    ? {
        type: fundraising.type,
        createTeam:
          fundraising.createTeam && fundraising.type === CampaignAction.CreateTeam
            ? serializeFundraisingCreateTeam(fundraising.createTeam, user, locale, organizationCountry)
            : undefined,
        inputs: serializeFundraisingInputs(fundraising, user, locale, organizationCountry),
      }
    : undefined;
