import { useContext, useEffect, useMemo } from "react";

import { useRouter } from "next/router";

import { FrontendFormContext } from "../contexts/FrontendFormContext";
import { SessionContext } from "../contexts/SessionContext";
import { FeatureFlagValue, TipSuggestionObject } from "../gql/gql-types";
import { useUpsertSessionMutation } from "../gql/queries/generated/sessionQuery";

import { NumberConstant } from "@/constants/number";

interface UpsertSessionProps {
  displayedFormAmount: number;
  tipSuggestions: [TipSuggestionObject, TipSuggestionObject, TipSuggestionObject];
  isSuggestedTipsAmount: boolean;
  tipExperimentValue: FeatureFlagValue | null;
}

export const useUpsertSession = ({
  displayedFormAmount,
  tipSuggestions,
  isSuggestedTipsAmount,
  tipExperimentValue,
}: UpsertSessionProps) => {
  const router = useRouter();
  const [execUpsertSession] = useUpsertSessionMutation();

  const { sessionId } = useContext(SessionContext);
  const { formData, formType } = useContext(FrontendFormContext);

  const userAgent = useMemo(
    () => ({
      appCodeName: process.browser ? window.navigator.appCodeName : "",
      appName: process.browser ? window.navigator.appName : "",
      language: process.browser ? window.navigator.language : "",
      appVersion: process.browser ? window.navigator.appVersion : "",
    }),
    []
  );
  const suggestedTips = useMemo(() => tipSuggestions?.map((tip) => tip.amount), [tipSuggestions]);

  useEffect(() => {
    execUpsertSession({
      variables: {
        sessionInput: {
          ...userAgent,
          url: router.asPath,
          isEmbed: router.asPath.includes("embed"),
          id: sessionId,
          formId: formData?.id,
          formType,
          amount:
            displayedFormAmount && displayedFormAmount < NumberConstant.GRAPHQL_MAX_INT
              ? Math.round(displayedFormAmount)
              : undefined,
          suggestedTips: suggestedTips?.[0] ? suggestedTips : undefined,
          defaultSuggestedTip: suggestedTips?.[0] ? suggestedTips?.[0] : undefined,
          isSuggestedTipsAmount,
          tipExperimentValue,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suggestedTips]);
};
