import { stringify } from "querystring";

import { FormStatus, FormType } from "@simplyk/common";

import { OrganizationRoutes } from "../../../routes/routes";

export const getEditFormLink = ({
  form,
  isSmallScreen,
}: {
  form: {
    status?: FormStatus;
    formType: FormType;
    id: string;
  };
  isSmallScreen: boolean;
}) => {
  if (form.status === FormStatus.Draft) {
    return form.formType === FormType.DonationForm
      ? `${OrganizationRoutes.DonationFormsNew}?${stringify({
          donationFormId: form.id,
          ...(isSmallScreen && { hideHubspotChatbot: true }),
        })}`
      : `${OrganizationRoutes.TicketingNew}?${stringify({
          ticketingId: form.id,
          ...(isSmallScreen && { hideHubspotChatbot: true }),
        })}`;
  }
  return form.formType === FormType.DonationForm
    ? OrganizationRoutes.DonationFormsEdit.replace(":id", form.id)
    : OrganizationRoutes.TicketingEdit.replace(":id", form.id);
};
