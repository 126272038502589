import { FC, useContext } from "react";

import { Box, ThemeProvider } from "@mui/material";

import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useTranslate } from "../../../hooks/useTranslate";
import { zeffyTheme } from "../../../theme/theme";
import { Icon } from "../../design-system/Icon";
import { Typography } from "../../design-system/Typography";
import { useSyncNotificationBannerHeight } from "../useSyncNotificationBannerHeight";

import { FrontendFormContext } from "@/contexts/FrontendFormContext";
import { Info } from "@/icons/outlined";

export const PaymentNotReadyBanner: FC = () => {
  const { isSmallScreen } = useMediaQuery();
  const { formData } = useContext(FrontendFormContext);
  const { t } = useTranslate();

  const ref = useSyncNotificationBannerHeight();

  const orgEmail = formData.organization?.ownerEmail;
  const isPaymentDisabled = formData.organization?.isPaymentDisabled;

  return (
    <ThemeProvider theme={zeffyTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: zeffyTheme.palette.surface.danger.quiet,
          paddingY: zeffyTheme.spacing(isSmallScreen ? 1 : 2),
          paddingX: zeffyTheme.spacing(isSmallScreen ? 2 : 6),
          ...(isSmallScreen && { position: "sticky", top: 0, zIndex: 1000 }),
        }}
        ref={ref}
        data-test="payment-not-ready-banner"
      >
        <Box>
          <Box display="flex" alignItems="center" justifyContent={isSmallScreen ? "center" : undefined}>
            {/* Icon */}
            {!isSmallScreen && (
              <Icon size="medium" vibe="text-danger-intense">
                <Info />
              </Icon>
            )}

            {/* Title */}
            <Typography
              variant="h6"
              vibe="text-danger-intense"
              sx={isSmallScreen ? { fontSize: 16, textAlign: "center" } : { marginLeft: 0.4 }}
            >
              {isPaymentDisabled
                ? t("common", "paymentNotReadyBanner.title.disabled")
                : t("common", "paymentNotReadyBanner.title")}
            </Typography>
          </Box>

          {/* Description */}
          <Typography
            variant="body2"
            vibe="text-danger-intense"
            sx={{ marginLeft: isSmallScreen ? 0 : 3, textAlign: isSmallScreen ? "center" : undefined }}
          >
            {t(
              "common",
              isPaymentDisabled ? "paymentNotReadyBanner.description.disabled" : "paymentNotReadyBanner.description",
              { orgEmail: orgEmail || "contact@zeffy.com" }
            )}
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
