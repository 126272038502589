import { memo } from "react";

import { Theme, styled } from "@mui/material/styles";
import MaterialSwitch from "@mui/material/Switch";
import { CSSProperties } from "@mui/styles/withStyles";

import { InternalClasses } from "../helpers";

import { SwitchBaseClasses, SwitchVibe } from "./types";

const PREFIX = "ZeffySwitch";

export const zeffySwitchClasses: InternalClasses<typeof PREFIX, SwitchBaseClasses> = {
  track: `${PREFIX}-track`,
  root: `${PREFIX}-root`,
  rootDisabled: `${PREFIX}-rootDisabled`,
  switchBase: `${PREFIX}-switchBase`,
  checked: `${PREFIX}-checked`,
  disabled: `${PREFIX}-disabled`,
  brand: `${PREFIX}-brand`,
  thumb: `${PREFIX}-thumb`,
};

const getVariantStyles = (vibe: SwitchVibe, theme: Theme): CSSProperties => {
  const trackVibeChecked = theme.palette.surface[vibe].intense;
  return {
    [`& .${zeffySwitchClasses.disabled} + .${zeffySwitchClasses.track}`]: {
      opacity: 1,
      backgroundColor: theme.palette.surface.neutral.moderate,
    },
    [`& .${zeffySwitchClasses.checked} + .${zeffySwitchClasses.track}`]: {
      backgroundColor: trackVibeChecked,
      opacity: 1,
    },
  };
};

export const StyledSwitch = memo(
  styled(MaterialSwitch)(({ theme }: { theme: Theme }) => ({
    [`& .${zeffySwitchClasses.track}`]: {
      width: 36,
      height: 20,
      borderRadius: 10,
      backgroundColor: theme.palette.surface.neutral.moderate,
    },

    [`&.${zeffySwitchClasses.root}`]: {
      padding: 0,
      width: 36,
      height: 20,
      [`& .${zeffySwitchClasses.switchBase}`]: {
        padding: 0,
      },
      [`& .${zeffySwitchClasses.checked}`]: {
        transform: "translateX(16px)",
      },
      [`& .${zeffySwitchClasses.track}`]: {
        opacity: 1,
      },
    },

    [`&.${zeffySwitchClasses.rootDisabled}`]: {
      opacity: 0.4,
    },

    [`&.${zeffySwitchClasses.brand}`]: getVariantStyles("brand", theme),

    [`& .${zeffySwitchClasses.thumb}`]: {
      width: 16,
      height: 16,
      color: theme.palette.surface.neutral.white,
      marginTop: 2,
      marginLeft: 2,
    },
  }))
);
