import { FC } from "react";

import { FormOwnerBanner } from "./FormOwnerBanner/FormOwnerBanner";
import { useShouldDisplayFormOwnerBanner } from "./FormOwnerBanner/useShouldDisplayFormOwnerBanner";
import { PaymentNotReadyBanner } from "./PaymentNotReadyBanner/PaymentNotReadyBanner";
import { useShouldDisplayPaymentNotReadyBanner } from "./PaymentNotReadyBanner/useShouldDisplayPaymentNotReadyBanner";

export const NotificationBanners: FC = () => {
  const shouldDisplayBanner = useShouldDisplayPaymentNotReadyBanner();
  const { value: shouldDisplayFormOwnerBanner } = useShouldDisplayFormOwnerBanner();

  return (
    <>
      {/* We should make sure that the PaymentNotReadyBanner and FormOwnerBanner components are rendered when needed to not break NotificationBannerHeightContext */}
      {shouldDisplayBanner && <PaymentNotReadyBanner />}
      {shouldDisplayFormOwnerBanner && <FormOwnerBanner />}
    </>
  );
};
