import { useContext } from "react";

import { FormType } from "@simplyk/common";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

import { IS_PREVIEW_QUERY_PARAM } from "../../../constants/url";
import { useCurrentUserContext } from "../../../contexts/CurrentUserContext";
import { FrontendFormContext } from "../../../contexts/FrontendFormContext";
import { useIsIframe } from "../../../hooks/useIsIframe";
import { useIsOnboarding } from "../../../hooks/useIsOnboarding";
import { PageLoadingContext } from "../../../providers/PageLoadingProvider";

export const useShouldDisplayPaymentNotReadyBanner = () => {
  const { organization, currentUser, userIsLoading } = useCurrentUserContext();
  const { formType, formData, isFreeTicketing, displayedFormAmount } = useContext(FrontendFormContext);
  const search = useSearchParams();
  const router = useRouter();
  const { isOnboarding } = useIsOnboarding();
  const isIframe = useIsIframe();
  const { isLoading } = useContext(PageLoadingContext);

  const isPreviewTemplateMode = router.query.previewTemplateMode === "true";
  const isPreview = search.get(IS_PREVIEW_QUERY_PARAM) === "true" || false;

  const isOwner = organization && currentUser && currentUser?.currentOrganization?.id === organization.id;

  const paymentFormTypes = [FormType.DonationForm, FormType.Ticketing];
  const isPaymentForm = formType && paymentFormTypes.includes(formType);

  const isFormPageDonorView = formData.id;

  const formOrg = formData.organization;

  const isPaymentReady = formOrg && formOrg.isStripeCustomAccountActive && !formOrg.isPaymentDisabled;

  return (
    !isOwner &&
    !isOnboarding &&
    !isPreviewTemplateMode &&
    !isPreview &&
    !isIframe &&
    isPaymentForm &&
    isFormPageDonorView &&
    !isPaymentReady &&
    !isLoading &&
    !userIsLoading &&
    !isFreeTicketing &&
    displayedFormAmount > 0
  );
};
