import { ParsedUrlQuery } from "querystring";

import "react-phone-input-2/lib/material.css";
import "react-phone-input-2/lib/style.css";

import { useState, useEffect } from "react";

import { Box, Stack } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, Theme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers-pro/AdapterLuxon";
import { Emptyable, FormType } from "@simplyk/common";
import { useRouter } from "next/router";

import { isProduction } from "../../constants/env";
import { CurrentUserProvider } from "../../contexts/CurrentUserContext";
import { LocaleContext, LocaleProvider } from "../../contexts/LocaleContext";
import { StripeAccountProvider } from "../../contexts/StripeAccountContext";
import { Locales, OrganizationObject } from "../../gql/gql-types";
import { useLocaleRedirection } from "../../hooks/useLocaleRedirection";
import { FrontendFormProvider } from "../../providers/FrontendFormProvider";
import { PageLoadingProvider } from "../../providers/PageLoadingProvider";
import { ZeffyLoader } from "../design-system/ZeffyLoader/ZeffyLoader";
import { DevContextCopy } from "../DevContextCopy/DevContextCopy";
import { HubspotChatbot } from "../HubspotChatbot/HubspotChatbot";
import { NotificationBannerHeightProvider } from "../NotificationBanners/NotificationBannerHeightContext";
import { NotificationBanners } from "../NotificationBanners/NotificationBanners";
import { UserConsent } from "../UserConsent/UserConsent";

import { ZeffySnackbarProvider } from "@/design-system/Snackbar/ZeffySnackbarProvider";
import { useDisableSafariBackForwardCache } from "@/hooks/useDisableSafariBackForwardCache";
import { zeffyTheme } from "@/theme/theme";
import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

interface AppFrontendProviderProps {
  theme?: Theme;
  formType?: FormType;
  formData?: DonationFormOutput | TicketingOutput;
  organization?: OrganizationObject;
  fields?: { locale: Locales }[];
  skipUserConsentModal?: boolean;
  isEmbed?: boolean;
  shouldDisplayLogin?: boolean;
  isFundraiser?: boolean;
  themeColor?: Emptyable<string>;
}

const HubspotChatflowTargets = {
  show: new Set(["o", "login", "register", "d"]),
  hide: new Set(["donation-form", "ticketing", "team", "peer-to-peer", "fundraising", "embed"]),
};

const hideHubspotChatbot = (query: ParsedUrlQuery, route: string) => {
  // Chatbot is hidden on form creation and edition
  if (Object.keys(query).length > 0 && (route.includes("/forms/edit") || route.includes("/forms/new"))) {
    return true;
  }

  // get first level path
  const path = route.split("/")[1];
  if (HubspotChatflowTargets.hide.has(path)) {
    return true;
  }

  return query.hideHubspotChatbot === "true" || !HubspotChatflowTargets.show.has(path);
};

export const AppFrontendProvider: React.FunctionComponent<React.PropsWithChildren<AppFrontendProviderProps>> = ({
  children,
  theme,
  formType,
  formData,
  organization,
  fields,
  skipUserConsentModal = true,
  isEmbed,
  shouldDisplayLogin,
  isFundraiser,
  themeColor,
}) => {
  const router = useRouter();
  useDisableSafariBackForwardCache();

  const { redirectionLocale } = useLocaleRedirection({
    country: organization?.country,
    fields,
  });

  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey(1);
  }, []);

  if (key === 0) {
    return <ZeffyLoader />;
  }

  const content = (
    <Stack direction="column" sx={{ minHeight: "100vh", position: "relative" }}>
      <NotificationBanners />

      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", height: "100%" }}>{children}</Box>
    </Stack>
  );

  return (
    <>
      {/* for router ready, see https://github.com/vercel/next.js/discussions/11484#discussioncomment-356055 */}
      <PageLoadingProvider
        isLoading={Boolean(!router.isReady || router.isFallback || redirectionLocale)}
        disableLoading
      >
        <ThemeProvider theme={theme || zeffyTheme}>
          <NotificationBannerHeightProvider>
            <CssBaseline />
            <CurrentUserProvider>
              <StripeAccountProvider>
                <LocaleProvider skipUserLocaleRedirection>
                  <LocaleContext.Consumer>
                    {(localeContext) => (
                      <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={localeContext.locale}>
                        <ZeffySnackbarProvider>
                          <DevContextCopy formId={formData ? formData.id : null} />

                          {organization && formData && formType ? (
                            <FrontendFormProvider
                              formData={formData}
                              organization={organization}
                              formType={formType}
                              isEmbed={isEmbed || false}
                              isFundraiser={isFundraiser || false}
                              shouldDisplayLogin={shouldDisplayLogin || false}
                              themeColor={themeColor || ""}
                            >
                              {content}
                            </FrontendFormProvider>
                          ) : (
                            <>{content}</>
                          )}

                          {!isProduction && !hideHubspotChatbot(router.query, router.route) && <HubspotChatbot />}
                          <UserConsent skipModal={skipUserConsentModal} />
                        </ZeffySnackbarProvider>
                      </LocalizationProvider>
                    )}
                  </LocaleContext.Consumer>
                </LocaleProvider>
              </StripeAccountProvider>
            </CurrentUserProvider>
          </NotificationBannerHeightProvider>
        </ThemeProvider>
      </PageLoadingProvider>
    </>
  );
};
