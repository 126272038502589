import { FormCategory } from "@simplyk/common";

import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

export const isTicketing = (form: TicketingOutput | DonationFormOutput): form is TicketingOutput => {
  return Boolean((form as TicketingOutput).ticketingFields);
};

export const isDonationForm = (form: TicketingOutput | DonationFormOutput): form is DonationFormOutput => {
  return Boolean((form as DonationFormOutput).donationFormFields);
};

export const isFormCategory = (category: unknown): category is FormCategory => {
  return typeof category === "string" && Object.values(FormCategory).includes(category as FormCategory);
};
