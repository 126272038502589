import { FC, useEffect } from "react";

import { Grid } from "@mui/material";
import { AmplitudeFlagKey, FormCategory, FormType } from "@simplyk/common";
import { useRouter } from "next/router";

import { useAmplitudeFeatureFlag } from "../../../hooks/amplitude/useAmplitudeFeatureFlag";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useTranslate } from "../../../hooks/useTranslate";
import { Icon } from "../../design-system/Icon";
import { Switch } from "../../design-system/Switch";
import { Tooltip } from "../../design-system/Tooltip";
import { Typography } from "../../design-system/Typography";

import { Info } from "@/icons/outlined";
import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

type SwitchFormVersionProps = {
  formObject: DonationFormOutput | TicketingOutput;
  formType: FormType;
};

const FORM_PATHS = {
  v1: {
    donationForm: "/donation-form",
    fundraising: "/fundraising",
    peerToPeer: "/peer-to-peer",
    ticketing: "/ticketing",
    team: "/team",
  },
  v2: {
    ticketing: "/ticketingV2",
  },
} as const;

export const SwitchFormVersion: FC<SwitchFormVersionProps> = ({ formObject, formType }) => {
  const { t } = useTranslate();
  const router = useRouter();
  const { isSmallScreen } = useMediaQuery();

  const { isActive: isFeatureFlagActive } = useAmplitudeFeatureFlag({
    flagKey:
      formType === FormType.DonationForm ? AmplitudeFlagKey.V2DonationFormToggle : AmplitudeFlagKey.V2AuctionFormToggle,
  });

  // Smooth transition when we switch the form version
  useEffect(() => {
    document.body.style.opacity = "1";
    document.body.style.transition = "opacity 0.3s ease-in";

    return () => {
      document.body.style.removeProperty("transition");
      document.body.style.removeProperty("opacity");
    };
  }, []);

  const isV2 = Object.values(FORM_PATHS.v2).some((path) => router.asPath.startsWith(path));

  if (!(formType === FormType.Ticketing && (formObject as TicketingOutput).formCategory === FormCategory.Auction)) {
    return null;
  }

  if (isFeatureFlagActive) {
    return null;
  }

  const getTargetPath = () => {
    return isV2
      ? router.asPath.replace(FORM_PATHS.v2.ticketing, FORM_PATHS.v1.ticketing)
      : router.asPath.replace(FORM_PATHS.v1.ticketing, FORM_PATHS.v2.ticketing);
  };

  const handleVersionSwitch = () => {
    document.body.style.opacity = "0";
    document.body.style.transition = "opacity 0.3s ease-out";

    setTimeout(() => {
      router.push(getTargetPath());
    }, 300);
  };

  return (
    <Grid container spacing={1}>
      {!isSmallScreen && (
        <Grid item alignSelf="center">
          <Typography variant="body2">{t("common", "memorable-form.toggle.v1")}</Typography>
        </Grid>
      )}
      <Grid item>
        <Switch checked={isV2} vibe="brand" data-test="toggle-version" onClick={handleVersionSwitch} />
      </Grid>

      <Grid item alignSelf="center">
        <Grid container>
          <Grid item>
            <Typography variant="body2">{t("common", "memorable-form.toggle.v2")}</Typography>
          </Grid>
          <Grid item>
            <Tooltip
              title={t("common", "memorable-form.toggle.tooltip")}
              arrow
              placement="top"
              sx={{
                marginLeft: 0.5,
                position: "relative",
                svg: {
                  position: "absolute",
                  top: "2px",
                },
              }}
            >
              <Icon pointer vibe="text-brand-moderate" size="small">
                <Info />
              </Icon>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
