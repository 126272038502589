import { TicketPurchase } from "@/types/ticketing";
import { RateOutput } from "@/types/trpc";

export const computeTicketsPrice = (rates: RateOutput[], tickets: TicketPurchase[] | undefined): number => {
  return (
    tickets?.reduce((prev, current) => {
      const rate = rates.find((rate) => rate.id === current.rateId);
      if (!rate || !current.purchasedNumber) {
        return prev;
      }

      return prev + current.purchasedNumber * rate.amount;
    }, 0) || 0
  );
};
