import { IsoLocale, getIsoLocaleFromCountry } from "@simplyk/common";

import { Locales, OrganizationCountry } from "../gql/gql-types";

export const getNavigatorLocale = () => {
  if (navigator.language.includes("fr")) {
    return Locales.FR;
  }
  if (navigator.language.includes("es")) {
    return Locales.ES;
  }
  return Locales.EN;
};

export const getFormRedirectionFromLocaleAndCountry = ({
  country,
  fields,
  isoLocale,
}: {
  fields: { locale: Locales }[];
  country?: OrganizationCountry | undefined;
  isoLocale: IsoLocale;
}): IsoLocale | null => {
  const formIsoLocales: IsoLocale[] = fields.map((field) => {
    return getIsoLocaleFromCountry(country, field.locale);
  });
  if (!formIsoLocales.includes(isoLocale)) {
    const nearestLocale = formIsoLocales.find((formIsoLocale) => {
      if (isoLocale.includes("en") && formIsoLocale.includes("en")) {
        return true;
      }
      if (isoLocale.includes("fr") && formIsoLocale.includes("fr")) {
        return true;
      }
      if (isoLocale.includes("es") && formIsoLocale.includes("es")) {
        return true;
      }
    });
    return (nearestLocale as IsoLocale) || formIsoLocales[0];
  }
  return null;
};
