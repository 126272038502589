import { DateTime } from "luxon";
export function getOrganizationClosingDate({ fourthPaymentDate, stripeCustomAccountActiveAtUtc, }) {
    const parsedFourthPaymentDate = fourthPaymentDate ? DateTime.fromJSDate(new Date(fourthPaymentDate)) : null;
    const parsedStripeCustomAccountDate = stripeCustomAccountActiveAtUtc
        ? DateTime.fromJSDate(new Date(stripeCustomAccountActiveAtUtc))
        : null;
    if (parsedFourthPaymentDate && parsedStripeCustomAccountDate) {
        return DateTime.max(parsedFourthPaymentDate, parsedStripeCustomAccountDate).toJSDate();
    }
    return null;
}
