import { createContext, FC, PropsWithChildren, useState } from "react";

interface NotificationBannerHeightContextType {
  bannerHeight: number;
  setBannerHeight: (height: number) => void;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

export const NotificationBannerHeightContext = createContext<NotificationBannerHeightContextType>({
  bannerHeight: 0,
  setBannerHeight: () => {},
  isExpanded: false,
  setIsExpanded: () => {},
});

export const NotificationBannerHeightProvider: FC<PropsWithChildren> = ({ children }) => {
  const [bannerHeight, setBannerHeight] = useState<number>(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const contextValue = {
    bannerHeight,
    setBannerHeight,
    isExpanded,
    setIsExpanded,
  };

  return (
    <NotificationBannerHeightContext.Provider value={contextValue}>{children}</NotificationBannerHeightContext.Provider>
  );
};
