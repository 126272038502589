import { Locales, formatDateTimeToLocaleString } from "@simplyk/common";

import { DiscountObject, Maybe } from "../gql/gql-types";

import { applyDiscountOnOrderValue } from "./order";

import { TicketPurchase, FullRateError } from "@/types/ticketing";
import { TicketingOutput, RateOutput } from "@/types/trpc";

export const getOrderAmountTotal = ({
  ticketsPurchased,
  rates,
  extraDonation,
  isAuction,
  discount,
  commandBidsRateIdsMap,
  highestBidsMapByRateId,
}: {
  ticketsPurchased: TicketPurchase[] | undefined;
  rates: RateOutput[];
  extraDonation: number;
  isAuction: boolean;
  discount?: DiscountObject | null;
  commandBidsRateIdsMap?: Record<string, { rateId: string; amount: number }> | null | undefined;
  highestBidsMapByRateId?: Record<string, { rateId: string; amount: number }> | null | undefined;
}) => {
  const currentTicketAmountWithoutDiscount =
    ticketsPurchased?.reduce((prev, current) => {
      const rate = rates?.find((rate) => rate.id === current.rateId);
      if (!rate) {
        return prev;
      }
      if (isAuction && current.bid) {
        return prev + current.bid;
      }
      return current.purchasedNumber ? prev + current.purchasedNumber * rate.amount : prev;
    }, 0) || 0;

  const oldBidsPurchasedAmount = commandBidsRateIdsMap
    ? Object.values(commandBidsRateIdsMap)
        ?.filter((bid) => {
          return (
            !ticketsPurchased?.find((ticket) => ticket.rateId === bid.rateId && ticket.bid && ticket.bid > 0) &&
            highestBidsMapByRateId?.[bid.rateId]?.amount === bid.amount
          );
        })
        .reduce((prev, current) => prev + current.amount, 0)
    : 0;

  const amountWithoutDiscount = currentTicketAmountWithoutDiscount + oldBidsPurchasedAmount;

  if (discount) {
    return applyDiscountOnOrderValue(discount, amountWithoutDiscount) + extraDonation;
  }

  return amountWithoutDiscount + extraDonation;
};

export const getEligibleAmountTotal = (
  ticketsPurchased: TicketPurchase[] | undefined,
  ticketing: TicketingOutput,
  extraDonation: number,
  discount?: DiscountObject | null
) => {
  const eligibleAmountWithoutDiscount =
    ticketsPurchased?.reduce((prev, current) => {
      const rate = ticketing.rates?.find((rate) => rate.id === current.rateId);
      if (!rate || !rate?.eligibleAmount) {
        return prev;
      }
      return current.purchasedNumber ? prev + current.purchasedNumber * rate.eligibleAmount : prev;
    }, 0) || 0;

  const extraDonationEligible = getExtraDonationEligible(ticketing, extraDonation, eligibleAmountWithoutDiscount);

  if (discount) {
    return applyDiscountOnOrderValue(discount, eligibleAmountWithoutDiscount) + extraDonationEligible;
  }
  return eligibleAmountWithoutDiscount + extraDonationEligible;
};

const getExtraDonationEligible = (ticketing: TicketingOutput, extraDonation: number, eligibleAmount: number) => {
  if (ticketing.generateExtraDonationReceipt) {
    // When generateExtraDonationReceipt si true, extra donation is eligible
    return extraDonation;
  } else {
    // When generateExtraDonationReceipt is false, extra donation is eligible if a receipt is generated
    if (eligibleAmount > 0) {
      return extraDonation;
    }
    // When generateExtraDonationReceipt is false, extra donation is NOT eligible if no receipt is generated
    return 0;
  }
};

export const getDisplayGenerateReceipt = (
  ticketsPurchased: TicketPurchase[] | undefined,
  ticketing: TicketingOutput | undefined,
  extraDonation: number
) => {
  if (!ticketing) {
    return false;
  }
  const oneTicketGenerateReceipt = ticketsPurchased?.some((selectedRate) => {
    const rate = ticketing?.rates?.find((rate) => rate.id === selectedRate?.rateId);
    return (
      selectedRate?.purchasedNumber &&
      selectedRate?.purchasedNumber > 0 &&
      rate?.eligibleAmount &&
      rate.eligibleAmount > 0
    );
  });
  const extraDonationGenerateReceipt = extraDonation && ticketing.generateExtraDonationReceipt;
  return Boolean(oneTicketGenerateReceipt || extraDonationGenerateReceipt);
};

export const concatExistingFullRatesWithNewError = (prevFullRates: FullRateError[], newFullRates: FullRateError[]) => {
  const prevFullRatesFiltered = prevFullRates.filter(
    (prevRateError) => !newFullRates.map((rateError) => rateError.id).includes(prevRateError.id)
  );
  return [...prevFullRatesFiltered, ...newFullRates];
};

export const getUniquelySelectedRate = (
  ticketsPurchased: TicketPurchase[] | undefined,
  availableRates: RateOutput[] | null | undefined
): RateOutput | undefined => {
  const ticketsPurchasedFiltered = ticketsPurchased?.filter(
    (ticket) => ticket.purchasedNumber && ticket.purchasedNumber.toString() === "1"
  );
  return ticketsPurchasedFiltered && ticketsPurchasedFiltered.length === 1
    ? availableRates?.find((rate) => rate.id === ticketsPurchasedFiltered[0].rateId)
    : undefined;
};

export const getUniquelySelectedTicketIndex = (
  ticketsPurchased: TicketPurchase[] | null | undefined,
  availableRates: RateOutput[] | null | undefined
): number => {
  if (!ticketsPurchased || ticketsPurchased.length === 0 || !availableRates || availableRates.length === 0) {
    return -1;
  }
  const uniquelySelectedRate = getUniquelySelectedRate(ticketsPurchased, availableRates);
  return ticketsPurchased.findIndex((ticket) => uniquelySelectedRate && ticket.rateId === uniquelySelectedRate.id);
};

export const hasSelectedTickets = (ticketsPurchased: TicketPurchase[] | undefined) => {
  return ticketsPurchased?.some((ticket) => ticket.purchasedNumber && ticket.purchasedNumber > 0);
};

export const filterEarlyBirdRate = (rate: RateOutput): boolean => {
  const earlyBirdDate = rate.earlyBirdCloseDate && rate.earlyBirdCloseDateTimezone && rate.earlyBirdCloseDate;
  return Boolean(
    !(rate.earlyBirdCloseDate && rate.earlyBirdCloseDateTimezone && earlyBirdDate) ||
      new Date(earlyBirdDate) > new Date()
  );
};

export const formatEarlyBirdDate = (
  earlyBirdCloseDate: Maybe<string> | undefined,
  earlyBirdCloseDateTimezone: Maybe<string> | undefined,
  locale: Locales
): string =>
  earlyBirdCloseDate && earlyBirdCloseDateTimezone
    ? formatDateTimeToLocaleString(earlyBirdCloseDate || "", locale, { day: "numeric", month: "short" })
    : "";

export const hasPlacedBids = (ticketsPurchased: TicketPurchase[] | undefined) => {
  return ticketsPurchased?.some((ticket) => ticket.bid && ticket.bid > 0);
};
