import { useEffect } from "react";

import { AmplitudeEvents } from "../../constants/amplitude";

import { useAmplitude } from "./useAmplitude";

export const useLogVisitPageAmplitudeEvent = ({
  event = AmplitudeEvents.PageViewed,
  payload,
  loading = false,
}: {
  event?: AmplitudeEvents;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Record<string, any>;
  loading?: boolean;
}) => {
  const { logAmplitudeEvent } = useAmplitude();

  useEffect(() => {
    if (!loading) {
      logAmplitudeEvent(event, payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);
};
